import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'matchType'
})

export class MatchTypeReplace implements PipeTransform {
  transform(value:string): string {
   switch (value) {
     case 'contains' : {return 'Contains';break;}
     case 'equals' : {return 'Equals';break;}
     case 'startsWith' : {return 'Starts With';break;}
     case 'endsWith' : {return 'Ends With';break;}
     case 'notequals' : {return 'Does not Equal';break;}
     case 'isnotnullorempty' : {return 'Is not empty';break;}
     case 'isnullorempty' : {return 'Is Empty';break;}
     case 'gt' : {return 'Greater Than';break;}
     case 'lt' : {return 'Less Than';break;}
     case 'lte' : {return 'Less Than or Equal';break;}
     case 'gte' : {return 'Greater Than or Equal';break;}
     case 'between' : {return 'Between';break;}
     case 'dateis' : {return 'Date is';break;}
     case 'dateisnot' : {return 'Date is Not';break;}
     case 'dateafter' : {return 'Date is After';break;}
     case 'datebefore' : {return 'Date is Before';break;}
     case 'daysfrompresent' : {return 'Days from Present';break;}
     case 'daysfrompresentbetween' : {return 'Days from Present Between';break;}
     case 'monthsfrompresentbetween' : {return 'Months from Present Between';break;}
     case 'monthsfrompresent' : {return 'Months from Present';break;}
   }
  }
}
