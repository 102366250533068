import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'state'
})
export class StatePipe implements PipeTransform {
  transform(state: string): string {
      return state.replace(/^.*(\| )/, '');
  }
}
