import {NgModule} from '@angular/core';

import {ToastModule} from 'primeng/toast';
import {SharedImports} from '@shared/shared-imports';
import {PublicComponent} from './public.component';
import {PublicRoutingModule} from './public-routing.module';


@NgModule({
    imports: [ToastModule,
        SharedImports,
        PublicRoutingModule, PublicComponent,
        PublicRoutingModule.components],
    exports: [PublicComponent],
})
export class PublicModule { }
