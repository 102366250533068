import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {ContextService} from './context.service';
import {InterfaceConfigListModel, UpdateInterfaceConfigSingleModel} from '@app/models/interface-config.model';

@Injectable({
  providedIn: 'root',
})
export class InterfaceConfigService {
  constructor(private http: HttpClient, private contextService: ContextService) {
  }

  getUserInterfaceConfigList(prefix?: string, key?: string): Observable<InterfaceConfigListModel[]> {
    const urlPrefix = prefix ? `?prefix=${prefix}` : '';
    const urlKey = key ? `?key=${key}` : '';
    return this.http.get<InterfaceConfigListModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/interfaceconfig/list${urlPrefix}${urlKey}`);
  }

  updateUserInterfaceSingle(key: string, dataModel: UpdateInterfaceConfigSingleModel): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/interfaceconfig/${key}/update`, dataModel);
  }

  updateUserInterfaceAll(dataModel: InterfaceConfigListModel[]): Observable<any> {
    return this.http.put<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/user/interfaceconfig/replaceall`, dataModel);
  }
}
