import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class SidenavService {
  private sidenavSource$ = new BehaviorSubject<boolean>(false);
  private sideAlertSource$ = new BehaviorSubject<boolean>(false);
  sidenav$ = this.sidenavSource$.asObservable();
  openCloseNav = false;
  sideAlert$ = this.sideAlertSource$.asObservable();
  openCloseAlert = false;

  updateMenuSideBar() {
    this.openCloseNav = !this.openCloseNav;
    this.sidenavSource$.next(this.openCloseNav);
  }

  updateAlertSideBar() {
    this.openCloseAlert = !this.openCloseAlert;
    this.sideAlertSource$.next(this.openCloseAlert);
  }
}
