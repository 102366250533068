export function activityDrivenMembers(): boolean {
  const show = [
    'IUPAT'
  ];
  return show.includes(sessionStorage.getItem('workingAs'));
}

export function paymentFileCustom(): boolean {
  const show = [
    'IUPAT'
  ];
  return show.includes(sessionStorage.getItem('workingAs'));
}

export function showBillingPublish(): boolean {
  const show = [
    'IUPAT'
  ];
  return show.includes(sessionStorage.getItem('workingAs'));
}

export function donotRequirePrimaryEmailCustom(): boolean {
  const show = [
    'IUPAT',
    'OPCMIA633'
  ];
  return show.includes(sessionStorage.getItem('workingAs'));
}

export function showMembershipCard(): boolean {
  const show = [
    'IUPAT'
  ];
  return show.includes(sessionStorage.getItem('workingAs'));
}

export function hideMemberContext(): boolean {
  const show = [
    'IMTEF'
  ];
  return show.includes(sessionStorage.getItem('workingAs'));
}

export function employmentTradeTab(): boolean {
  const show = [
    'IUPAT',
    'NCFO'];
  return show.includes(sessionStorage.getItem('workingAs'));
}

export function contractCustom(): boolean {
  const show = [
    'IUPAT'
  ];
  return show.includes(sessionStorage.getItem('workingAs'));
}

export function employerContactCustom(): boolean {
  const show = [
    'IUPAT'
  ];
  return show.includes(sessionStorage.getItem('workingAs'));
}

export function addToRosterMemberNumberCustom(): boolean {
  const show = [
    'CWA6182',
    'UEA',
    'WEA'
  ];
  return show.includes(sessionStorage.getItem('workingAs'));
}

export function addToRosterMemberNumberReqCustom(): boolean {
  const show = [
    'UEA',
    'WEA'
  ];
  return show.includes(sessionStorage.getItem('workingAs'));
}

export function oneTimeContributionExtraTextCustom(): boolean {
  const show = [
    'WEA'
  ];
  return show.includes(sessionStorage.getItem('workingAs'));
}

export function customAttributesAddUser(): boolean {
  const show = [
    'IUPAT'
  ];
  return show.includes(sessionStorage.getItem('workingAs'));
}

export function programFacetRecommendedHoursRequired(): boolean {
  const show = [
    'IUPAT'
  ];
  return show.includes(sessionStorage.getItem('workingAs'));
}

export function raiseRequestRecipientCheck(): boolean {
  const show = [
    'IUPAT'
  ];
  return show.includes(sessionStorage.getItem('workingAs'));
}

export function hideFacetSummary(): boolean {
  const show = [
    'IUPAT'
  ];
  return show.includes(sessionStorage.getItem('workingAs'));
}

export function enforcePasswordValidation(): boolean {
  const show = [
    'IUPAT'
  ];
  return show.includes(JSON.parse(sessionStorage.getItem('contextObject'))?.tenantName);
}

export function customPersonnelFiles(): boolean {
  const show = [
    'IUPAT'
  ];
  return show.includes(JSON.parse(sessionStorage.getItem('contextObject'))?.tenantName);
}

export function customTradeFilter(): boolean {
  const show = [
    'IUPAT'
  ];
  return show.includes(sessionStorage.getItem('workingAs'));
}

export function setPageRowCountDefault100(): boolean {
  const show = [
    'IUPAT'
  ];
  return show.includes(sessionStorage.getItem('workingAs'));
}

export function setRosterStudentMembershipTabReadOnly(): boolean {
  const show = [
    'IUPAT'
  ];
  return show.includes(sessionStorage.getItem('workingAs'));
}

export function setClassModuleFilterDefault(): boolean {
  // used to set the default filter to all classes, not active only
  const show = [
    'IMTEF'
  ];
  return show.includes(sessionStorage.getItem('workingAs'));
}

export function separateCustomFieldsOnProfile(): boolean {
  const show = [
    'IUPAT'
  ];
  return show.includes(sessionStorage.getItem('workingAs'));
}

export function addHelmetsToHardhatsCustomField(): boolean {
  const show = [
    'IUPAT'
  ];
  return show.includes(sessionStorage.getItem('workingAs'));
}

export function hideApprovedHoursReset(): boolean {
  const show = [
    'IUPAT'
  ];
  return show.includes(sessionStorage.getItem('workingAs'));
}

export function customAttendanceSheet(): boolean {
  const show = [
    '602TRAINING'
  ];
  return show.includes(sessionStorage.getItem('workingAs'));
}

export function usePaidThroughBillingPeriod(): boolean {
  const show = [
    'OPCMIA633'
  ];
  return show.includes(sessionStorage.getItem('workingAs'));
}

export function simplifiedPaymentAdjustment(): boolean {
  const show = [
    'UNSU'
  ];
  return show.includes(sessionStorage.getItem('workingAs'));
}

export function hideContactSupport(): boolean {
  const show = [
    'IUPAT'
  ];
  return show.includes(sessionStorage.getItem('workingAs'));
}

export function showAcademicTranscript(): boolean {
  const show = [
    'IUPAT'
  ];
  return show.includes(sessionStorage.getItem('workingAs'));
}
