import {Component, ElementRef, Input, isDevMode, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {AbstractControl, FormArray, FormControl, ValidationErrors,} from '@angular/forms';
import {LookupsDataService} from '@services/lookups-data.service';
import {ContextModel} from '@app/models/context.model';
import {EnvironmentsService} from '@services/environments.service';
import {ContextService} from '@services/context.service';
import {ActivatedRoute, Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {UserRegistrationService} from '@services/user-registration.service';
import {MessageService} from 'primeng/api';
import {Subject} from 'rxjs';
import {SharedImports} from '@shared/shared-imports';
import {UserAccountService} from '@services/user-account.service';
import {AuthService} from '@app/core/services/auth.service';
import {Title} from '@angular/platform-browser';
import {UserTfaModel} from '@app/models/user-account.model';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';


function getFormArray(size: number): FormArray {
  const arr = [];

  for (let i = 0; i < size; i++) {
    arr.push(new FormControl(''));
  }

  return new FormArray(arr);
}

@Component({
  selector: 'app-two-factor-auth',
  templateUrl: './two-factor-auth.component.html',
  styleUrls: ['./two-factor-auth.component.scss'],
   standalone: true,

  imports: [SharedImports]
})
export class TwoFactorAuthComponent implements OnInit {
  logo: string;
  tfaData: UserTfaModel;
  phoneNumber: string;
  validationCode: string;
  twoFactorAuth: boolean;
  isValidating: boolean;
  isLogin: boolean;
  onChange?: (value: string) => void;
  onTouched?: () => void;
  @ViewChild('fieldsetElement') fieldSetElement!: ElementRef;
  @ViewChildren('inputEl') inputEls!: QueryList<ElementRef<HTMLInputElement>>;
  #size = 6;
  inputs = getFormArray(this.#size);
  #scheduledFocus: number = 0;
  private ngUnsubscribe = new Subject();

  constructor(private lookupsDataService: LookupsDataService, private ref: DynamicDialogRef,
              private environmentsService: EnvironmentsService, private contextService: ContextService,
              private route: ActivatedRoute, private userRegistrationService: UserRegistrationService,
              private messageService: MessageService, private router: Router,
              private authService: AuthService, private userAccountService: UserAccountService,
              private titleService: Title, private config: DynamicDialogConfig) {

  }

  @Input() set size(size: number) {
    this.inputs = getFormArray(size);
    this.#size = size;
  }

  ngOnInit(): void {
    this.isLogin = this.config.data.isLogin;
    this.twoFactorAuth = this.config.data.twoFactorAuth;
    if (this.config.data.phoneNumber) {
      this.phoneNumber = this.config.data.phoneNumber;
    }
    if (this.phoneNumber) {
      this.updateUserTfaPrefs(true);
    }

    setTimeout(() => {
      const parentElement = this.fieldSetElement.nativeElement;
      const firstChild = parentElement.children[1];
      firstChild.focus();
    }, 300);


  }

  getBaseUrl() {
    return new Promise((resolve, reject) => {
      this.environmentsService.getEnvironment(window.location.hostname).then((rtnTenant) => {
        if (rtnTenant) {
          this.setUrl(rtnTenant);
        }
      });
    });
  }

  setUrl(rtnTenant) {
    const context = {} as ContextModel;
    context.apiBaseUrlV1 = rtnTenant.apiBaseUrlV1;
    context.tenantName = rtnTenant.tenantEnum;
    context.tenantLogo = rtnTenant.tenantLogo;
    context.accessToken = null;
    context.multiTenant = rtnTenant.multiTenant;

    this.titleService.setTitle(rtnTenant.pageTitle);
    sessionStorage.setItem('pageTitle', rtnTenant.pageTitle);
    this.contextService.contextObject = context;
    this.logo = this.contextService.contextObject.tenantLogo;
  }

  writeValue(value: string): void {
    if (isDevMode() && value?.length) {
      throw new Error('Otp input is not supposed to be prefilled with data');
    }

    // Reset all input values
    this.inputs.setValue(new Array(this.#size).fill(''));
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.inputs.disable();
    } else {
      this.inputs.enable();
    }
  }

  validate(control: AbstractControl<string, string>): ValidationErrors | null {
    if (!control.value || control.value.length < this.#size) {
      return {
        otpInput: 'Value is incorrect',
      };
    }

    return null;
  }

  handleKeyDown(e: KeyboardEvent, idx: number) {
    if (e.key === 'Backspace' || e.key === 'Delete') {
      this.#focusInput(this.#scheduledFocus);
      if (idx > 0) {
        this.#scheduledFocus = idx - 1;
      }
    }
    if (e.key === 'Enter' && this.validationCode?.length === 6) {
      this.processSubmit(false);
    }
  }

  // Due to iOS/iPadOS Safari bug/special behavior we are forced to
  // schedule focus transition during keypress/keydown event and only
  // after input event happened - execute the transition
  // otherwise inputs don't get their values filled
  handleInput() {
    this.#updateWiredValue();

    if (this.#scheduledFocus != null) {
      this.#focusInput(this.#scheduledFocus);
      this.#scheduledFocus = null;
    }
  }

  handleKeyPress(e: KeyboardEvent, idx: number) {
    const isDigit = /\d/.test(e.key);

    // Safari fires Cmd + V through keyPress event as well
    // so we need to handle it here and let it through
    if (e.key === 'v' && e.metaKey) {
      return true;
    }

    if (isDigit && idx + 1 < this.#size) {
      // If user inputs digits & we are not on the last input we want
      // to advance the focus
      this.#scheduledFocus = idx + 1;
    }

    if (isDigit && this.inputs.controls[idx].value) {
      // If user deselects an input which already has a value
      // we want to clear it so that it doesn't have more than 1 digit
      this.inputs.controls[idx].setValue('');
    }

    return isDigit;
  }

  handlePaste(e: ClipboardEvent, idx: number) {
    e.preventDefault();

    if (idx !== 0) {
      // If the target input is not the first one - ignore
      return;
    }

    const pasteData = e.clipboardData?.getData('text');
    const regex = new RegExp(`\\d{${this.#size}}`);

    if (!pasteData || !regex.test(pasteData)) {
      // If there is nothing to be pasted or the pasted data does not
      // comply with the required format - ignore the event
      return;
    }

    for (let i = 0; i < pasteData.length; i++) {
      this.inputs.controls[i].setValue(pasteData[i]);
    }

    this.#focusInput(this.inputEls.length - 1);
    this.#updateWiredValue();
    this.onTouched();
  }

  handleFocus(e: FocusEvent) {
    // Select previously entered value to replace with a new input
    console.log(e);
    (e.target as HTMLInputElement).select();
  }

  resendCode() {
    if (this.isLogin) {
      this.ref.close('1111111');
    } else {
      this.validationCode = null;
      this.updateUserTfaPrefs(true);
    }

  }

  processSubmit(precheck) {
    if (!this.isLogin) {
      this.updateUserTfaPrefs(precheck);
    } else {
      this.ref.close(this.validationCode);
    }
  }

  updateUserTfaPrefs(precheck: boolean) {
    if (!precheck) {
      this.isValidating = true;
    } else {
      this.isValidating = false;
    }
    this.tfaData = {
      TwoFactorEnabled: this.twoFactorAuth,
      PhoneNumber: this.phoneNumber,
      Code: this.validationCode
    };
    this.userAccountService.putAccountTfa(this.tfaData)
      .pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: () => {
        if (!precheck) {
          this.isValidating = false;
          this.ref.close();
        }
        this.isValidating = false;
      }, error: (e) => {
        this.isValidating = false;
        this.messageService.add({severity: 'error', key: 'twoFA', summary: 'Error', detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'});
        console.debug(e);
      }
    });
  }

  #focusInput(idx: number) {
    // In order not to interfere with the input we setTimeout
    // before advancing the focus
    setTimeout(() => this.inputEls.get(idx)?.nativeElement.focus());
  }

  #updateWiredValue() {
    // We want to expose the value as a plain string
    //
    // In order not to interfere with the input we setTimeout
    // before advancing the focus
    setTimeout(() => this.onChange?.(this.inputs.value.join('')));
    this.validationCode = this.inputs.value.join('');
  }
}
