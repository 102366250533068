import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {ContextService} from './context.service';
import {MemberVerificationHashedIdModel, MemberVerificationModel} from '@app/models/member-verification.model';


@Injectable({
  providedIn: 'root',
})
export class MemberVerificationService {
  constructor(private http: HttpClient, private contextService: ContextService) {
  }

  getMemberVerification(modelData: MemberVerificationHashedIdModel, tenantName: string): Observable<MemberVerificationModel> {
    console.log(this.contextService.contextObject.apiBaseUrlV1);
    return this.http.post<MemberVerificationModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/integration/${tenantName}/membershipverificationcard`, modelData);
  }
}
