import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {AlertsService} from '@app/core/services/alerts.service';
import {AlertModel} from '@app/models/alerts.model';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ContextService} from '@services/context.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {Router} from '@angular/router';
import {SharedImports} from '@shared/shared-imports';
import {AlertsModel} from '@app/core/models/alerts.model';

@Component({
  selector: 'app-alerts-sidebar',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
   standalone: true,

  imports: [SharedImports]
})
export class AlertsComponent implements OnInit, OnDestroy {
  alerts: AlertsModel;
  alertsList: AlertModel[];
  clearAlertModel: number[] = [];
  dataLoaded: boolean;
  @Output() close = new EventEmitter<boolean>();

  private ngUnsubscribe = new Subject();

  constructor(private alertsService: AlertsService, private contextService: ContextService,
              private messageService: MessageService, private router: Router,
              private confirmationService: ConfirmationService,) {
  }

  ngOnInit(): void {
    this.getAlerts();
  }

  getAlerts(): void {
    if (this.contextService.contextObject.selContext && this.contextService.contextObject.SuperUser) {
      this.dataLoaded = false;
      this.alertsService.getAlerts(this.contextService.contextObject.selContext).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
        next: (res) => {
          this.alertsList = res.Alerts;
          this.dataLoaded = true;
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  clearAlert(id): void {
    this.clearAlertModel.push(id);
    this.alertsService.clearAlert(this.clearAlertModel)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success', summary: 'Success',
            detail: 'Alert was cleared'
          });
          this.getAlerts();
        }, error: (e) => {
          this.messageService.add({
            severity: 'error', summary: 'Error',
            detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
          });
          console.debug(e);
        }
      });
  }

  closeAlerts(): void {
    this.close.emit(false);
    this.router.navigateByUrl('alerts/list');
  }

  clearAll(): void {
    this.alertsList.forEach(alert => {
      this.clearAlertModel.push(alert.SharableId);
    });
    this.alertsService.clearAlert(this.clearAlertModel)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success', summary: 'Success',
            detail: 'Alerts were cleared'
          });
          this.getAlerts();
        }, error: (e) => {
          this.messageService.add({
            severity: 'error', summary: 'Error',
            detail: 'Our apologies... Something went sideways. Please try again and let us know if it continues. Thank you!'
          });
          console.log(e);
        }
      });
  }

  jumpTo(id: number, goURL: string): void {
    this.confirmationService.confirm({
      header: 'Go to Target',
      icon: 'fas fa-bullseye-arrow',
      message: 'Do you want to go to the target of this alert?',
      accept: () => {
        this.clearAlert(id);
        this.router.navigateByUrl(goURL);
        this.close.emit(false);
      }
    });
  }
}
