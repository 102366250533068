import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {ContextService} from './context.service';

import {
  UserRegistrationModel, UserRegistrationAutoLinkModel,
  UserRegistrationUpgradeModel, UserRegistrationInviteUserModel,
  UserRegistrationInviteUsersModel, UserRegistrationVerifyAccountModel,
  UserRegistrationVerifyInvitationModel, UserRegistrationLinkAccountModel,
  UserRegistrationLogLinkFailureModel, UserRegistrationClassModel,
  UserRegistrationEventModel
} from '@app/models/user-registration.model';

@Injectable({
  providedIn: 'root',
})
export class UserRegistrationService {
  constructor(private http: HttpClient, private contextService: ContextService) {
  }

  registerUser(modelData: UserRegistrationModel): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/account/register`, modelData);
  }

  registerUserAutoLink(modelData: UserRegistrationAutoLinkModel): Observable<any> {
    // ADMIN ONLY
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/account/register`, modelData);
  }

  upgradeRegistration(modelData: UserRegistrationUpgradeModel): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/account/upgraderegistration`, modelData);
  }

  inviteUser(modelData: UserRegistrationInviteUserModel): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/account/invite`, modelData);
  }

  inviteUsers(modelData: UserRegistrationInviteUsersModel): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/account/inviteorganizationusers?async=false`, modelData);
  }

  resendBulkInvitations(recipientIds: number[]): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/account/resendbulkinvitation`, recipientIds);
  }

  verifyAccount(modelData: UserRegistrationVerifyAccountModel): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/account/verifyaccount`, modelData);
  }

  verifyInvitation(modelData: UserRegistrationVerifyInvitationModel): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/account/verifyinvitation`, modelData);
  }

  linkAccount(modelData: UserRegistrationLinkAccountModel): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/account/linkmyaccount`, modelData);
  }

  logLinkFailure(modelData: UserRegistrationLogLinkFailureModel): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/account/loglinkfail`, modelData);
  }

  classRegistration(modelData: UserRegistrationClassModel): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/account/classregistrationv2`, modelData);
  }

  eventRegistration(modelData: UserRegistrationEventModel): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/account/eventregistration`, modelData);
  }
}
