import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {AuthService} from '@app/core/services/auth.service';
import {SidenavService} from '@app/core/services/sidenave-service';
import {UserProfileService} from '@services/user-profile.service';
import {AlertsService} from '@app/core/services/alerts.service';
import {formatDate} from '@angular/common';
import {ContextService} from '@services/context.service';
import {ConfirmationService} from 'primeng/api';
import {DymoLabelUtility} from '@shared/utilities/dymo-label.utility';
import {ContextModel, tenantConfigsModel} from '@app/models/context.model';
import {TenantConfigService} from '@services/tenant-config.service';
import {RosterService} from '@services/roster.service';
import {PrimeTableFilterModel} from '@app/models/table-filter.model';
import {DialogService} from 'primeng/dynamicdialog';
import {ContactSupportDialogComponent} from '@shared/components/contact-support-dialog/contact-support-dialog.component';
import {DataPerspectiveDropdownComponent} from '../data-perspective-dropdown/data-perspective-dropdown.component';
import {SharedImports} from '@shared/shared-imports';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  host: {
    '(window:click)': 'onWindowClick()'
  },
  standalone: true,
  imports: [SharedImports, DataPerspectiveDropdownComponent]
})

export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  isSuperUser: boolean = false;
  dataPerspectiveOpen: boolean = false;
  menuOpen: boolean = false;
  alertCount: number = 0;
  alertsOpen: boolean = false;
  @Output() MenuSelect = new EventEmitter<any>();
  @Output() MenuViewSelect = new EventEmitter<any>();
  menu: string = 'top';
  public showBurger: boolean = false;
  personPhotoSrc: string = '../../../../assets/images/user@2x.png';
  personName: string;
  loggedIn: string;
  showMenuViewDD: boolean = false;
  showWorkingAs: boolean;
  selectedWorkingAs: string;
  placeholder = 'Member #';
  buttonIcon = 'Aa';
  isNameSearch: boolean;
  searchData: string;
  suggestions: any[];
  selectedMember;
  searching: boolean;
  tenantContext: string = 'membership';
  hasMultipleContext: boolean;
  currentFilter = new PrimeTableFilterModel();
  @Input() showMenuSelect: boolean;
  @Input() parentShowBurger: boolean;
  getListRequest;

  options = [{
    description: 'IAFF',
  }, {
    description: 'IUPAT',
  }, {
    description: 'CWA6182',
  }, {
    description: 'UA486',
  },
    {
      description: 'IMTEF',
    },
    {
      description: '602TRAINING',
    },
    {
      description: 'OPCMIA633',
    },
    {
      description: 'UNSU',
    }
  ];
  pipe: any;
  private ngUnsubscribe = new Subject();

  constructor(private authService: AuthService, private sidenavService: SidenavService,
              private alertService: AlertsService, private userProfileService: UserProfileService,
              private router: Router, private contextService: ContextService, private dialogService: DialogService,
              private confirmationService: ConfirmationService, private dymoLabelUtility: DymoLabelUtility,
              private tenantConfigService: TenantConfigService, private rosterService: RosterService) {

  }

  ngOnInit(): void {
    this.router.events.subscribe({
      next: (event: any) => {
        if (event instanceof NavigationEnd) {
          this.getAlerts();
        }
      }
    });
    if (sessionStorage.getItem('workingAs') === 'undefined' || sessionStorage.getItem('workingAs') === null) {
      sessionStorage.setItem('workingAs', this.contextService.contextObject.tenantName);
    }
    console.log('working as = ' + sessionStorage.getItem('workingAs'));
    this.selectedWorkingAs = sessionStorage.getItem('workingAs');
    const context = this.contextService.contextObject;
    if (sessionStorage.getItem('env') === 'dev') {
      this.showWorkingAs = true;
    }
    this.isSuperUser = false;
    if (context && context !== 'null') {
      if (context.SuperUser) {
        this.isSuperUser = context.SuperUser;
      }
      this.getProfilePhoto(context.PersonId);
      this.personName = context.PersonName;
    } else {
      this.personPhotoSrc = '../../../../assets/images/user@2x.png';
      this.personName = '';
    }
    const format = 'MM/dd/yyyy h:mm:ss a';
    const myDate = this.contextService.contextObject.loggedIn;
    const locale = 'en-US';
    this.loggedIn = formatDate(myDate, format, locale);

    // pre-load tenant configs
    this.tenantConfigService.getTenantConfigAll()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res: tenantConfigsModel[]) => {
          const context1: ContextModel = this.contextService.contextObject;
          context1.tenantConfigs = res;
          this.contextService.contextObject = context1;
        }, error: () => {
          // do nothing
        }
      });

    // pre-load dymo
    this.dymoLabelUtility.isDymoInstalled().then(() => {
      this.dymoLabelUtility.isDymoPrinterInstalled();
    }).catch(() => {
      // do nothing
    });

    this.currentFilter.sortOrder = 1;
    this.currentFilter.first = 0;
    this.currentFilter.filters = {};

    if (this.contextService.contextObject.Contexts?.length === 1 && this.contextService.contextObject.Contexts.includes('TRAINING')) {
      this.tenantContext = 'training';
      this.hasMultipleContext = false;
    } else if (this.contextService.contextObject.Contexts?.length === 1 && this.contextService.contextObject.Contexts.includes('MEMBERSHIP')) {
      this.tenantContext = 'membership';
      this.hasMultipleContext = false;
    } else {
      this.hasMultipleContext = true;
    }

  }

  toggleSearch() {
    this.isNameSearch = !this.isNameSearch;
    this.selectedMember = null;
    if (this.isNameSearch && this.tenantContext === 'membership') {
      this.placeholder = 'Member Name';
      this.buttonIcon = '#';
    } else if (this.tenantContext === 'training' && this.isNameSearch) {
      this.placeholder = 'Student Name';
      this.buttonIcon = '#';
    } else if (this.tenantContext === 'training' && !this.isNameSearch) {
      this.placeholder = 'Student #';
      this.buttonIcon = 'Aa';
    } else if (this.tenantContext === 'membership' && !this.isNameSearch) {
      this.placeholder = 'Member #';
      this.buttonIcon = 'Aa';
    }
  }

  toggleContext() {
    this.isNameSearch = false;
    if (this.tenantContext === 'membership') {
      this.tenantContext = 'training';
      this.placeholder = 'Student #';
    } else {
      this.tenantContext = 'membership';
      this.placeholder = 'Member #';
    }
    this.buttonIcon = 'Aa';
  }

  searchMembers(event: any) {
    // console.log(this.tenantContext);
    this.searching = true;
    if (this.getListRequest) {
      this.getListRequest.unsubscribe();
    }
    this.currentFilter.filters = {};
    if (this.tenantContext === 'membership') {
      if (!this.isNameSearch) {
        this.currentFilter.filters.MemberNumber = [{
          matchMode: 'contains',
          value: event.query,
          operator: 'and',
        }];
      } else {
        this.currentFilter.filters.FullName = [{
          matchMode: 'contains',
          value: event.query,
          operator: 'and',
        }];
      }
    } else {
      if (!this.isNameSearch) {
        this.currentFilter.filters.StudentNumber = [{
          matchMode: 'contains',
          value: event.query,
          operator: 'and',
        }];
      } else {
        this.currentFilter.filters.FullName = [{
          matchMode: 'contains',
          value: event.query,
          operator: 'and',
        }];
      }
    }

    if (this.tenantContext === 'training') {
      this.getListRequest = this.rosterService.getRosterLitePrimeNG('DATA', this.currentFilter)
        .pipe()
        .subscribe({
          next: (res) => {
            this.suggestions = res.Data;
            this.searching = false;
          }, error: (e) => {
            console.debug(e);
          }
        });
    } else {
      this.getListRequest = this.rosterService.getRosterLitePrimeNG('DATA', this.currentFilter)
        .pipe()
        .subscribe({
          next: (res) => {
            this.suggestions = res.Data;
            this.searching = false;
          }, error: (e) => {
            console.debug(e);
          }
        });
    }


  }

  getMember(member) {
    this.router.navigateByUrl('/membership/roster/edit/' + member.PersonID + '?selRosterId=' + member.PersonID);
    setTimeout(() => {
      this.selectedMember = '';
    }, 400);

  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngAfterViewInit(): void {
    // check for alert updates every 30 minutes
    // setInterval(() => {
    //   if (!this.router.url.includes('login')) {
    //use commented out code for testing token expire/renew
    // const tempContext:ContextModel = this.contextService.contextObject;
    // tempContext.accessToken = tempContext.accessToken + '1';
    // this.contextService.contextObject = tempContext;
    // console.log(this.contextService.contextObject);
    //     this.getAlerts();
    // }
    // }, 60 * 30000);
    this.showBurger = this.parentShowBurger;
  }

  getAlerts(): void {
    this.alertService.getAlerts().pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (res) => {
        this.alertCount = res.Count;
      }
    });
  }

  getProfilePhoto(personId: number): void {
    this.userProfileService.getUserProfilePhoto(personId)
      .pipe(take(1)).subscribe({
      next: (res) => {
        if (res.length === 4) {
          this.personPhotoSrc = res[1].Url;
        } else {
          this.personPhotoSrc = '../../../../assets/images/user@2x.png';
        }
      }, error: () => {
        this.personPhotoSrc = '../../../../assets/images/user@2x.png';
      }
    });
  }

  toggleMenu($event): void {
    $event.stopPropagation();
    this.menuOpen = !this.menuOpen;
  }

  toggleAlertMenu(): void {
    this.sidenavService.updateAlertSideBar();
  }

  onWindowClick(): void {
    // this.dataPerspectiveOpen = false;
    this.menuOpen = false;
    this.alertsOpen = false;
  }

  logOut(): void {
    this.authService.logout();
  }

  openCloseNav(): void {
    this.sidenavService.updateMenuSideBar();
  }

  menuView(): void {
    this.showMenuViewDD = !this.showMenuViewDD;
  }

  menuSelected(): void {
    if (this.menu === 'side') {
      this.menu = 'top';
      this.parentShowBurger = false;

    } else {
      this.menu = 'side';
      this.parentShowBurger = true;
    }
    this.openCloseNav();
    this.MenuSelect.emit(this.menu);
  }

  ShowDropDown(): void {
    this.dataPerspectiveOpen = false;
  }

  openDataPerspective($event): void {
    // $event.stopPropagation();
    this.dataPerspectiveOpen = !this.dataPerspectiveOpen;
  }

  goToDashboard() {
    if (this.contextService.contextObject.Administrator) {
      this.router.navigateByUrl('widget-dashboard?type=admin');
    } else {
      this.router.navigateByUrl('dashboard');
    }
  }

  resetWarning(): void {
    this.confirmationService.close();
  }

  processClient(client): void {
    sessionStorage.setItem('workingAs', client.value);
    this.selectedWorkingAs = client;
    setTimeout(() => {
      window.location.reload();
    }, 300);
  }

  contactSupport() {
    const ref = this.dialogService.open(ContactSupportDialogComponent, {
      header: 'Contact Support',
      width: '60%',
      height: '400'
    });
  }
}
