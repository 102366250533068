import {enableProdMode, importProvidersFrom} from '@angular/core';

import {environment} from './environments/environment';
import {bootstrapApplication, BrowserModule, Title} from '@angular/platform-browser';
import {EnvironmentsService} from '@services/environments.service';
import {AppRoutingModule} from '@app/app-routing.module';
import {LoginModule} from '@app/login/login.module';
import {PublicModule} from '@app/public/public.module';
import {SharedImports} from '@shared/shared-imports';
import {BrowserAnimationsModule, provideAnimations} from '@angular/platform-browser/animations';
import {SidebarModule} from 'primeng/sidebar';
import {AppComponent} from '@app/app.component';
import {MessageService} from 'primeng/api';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(BrowserModule, AppRoutingModule, LoginModule, PublicModule, SharedImports, SidebarModule, BrowserAnimationsModule),
    Title,
    EnvironmentsService,
    MessageService,
    provideAnimations()
  ]
})
  .catch(err => console.error(err));

//Initialize Userpilot
//Userpilot.initialize('NX-bd2bc21a'); //`appToken` should be replaced with your userpilot appToken.

