import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {SharedImports} from '@shared/shared-imports';
import {takeUntil} from 'rxjs/operators';
import {MessageService} from 'primeng/api';

import {UserAccountUnsubscribeModel} from '@app/models/user-account.model';
import {UserAccountService} from '@services/user-account.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {EnvironmentsService} from '@services/environments.service';
import {ContextService} from '@services/context.service';
import {environment} from '../../../environments/environment';
import {ContextModel} from '@app/models/context.model';
import {Title} from '@angular/platform-browser';

@Component({
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss'],
   standalone: true,

  imports: [SharedImports]
})
export class UnsubscribeComponent implements OnInit, OnDestroy {
  uForm: FormGroup;
  userAccountUnsubscribeModel: UserAccountUnsubscribeModel;
  isLoading: boolean = false;
  tenant: string;
  logo: string;
  qptid: number;
  qpspid: number;
  qppid: number;

  private ngUnsubscribe = new Subject();

  constructor(private userAccountService: UserAccountService, private router: Router,
              private route: ActivatedRoute, private messageService: MessageService, private formBuilder: FormBuilder,
              private environmentsService: EnvironmentsService,
              private contextService: ContextService, private titleService: Title) {
    this.uForm = this.formBuilder.group({
      confirm: new FormControl(''),
      email: new FormControl('', [Validators.required, Validators.pattern('^(([^<>()[\\]\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\\]\\.,;:\\s@\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\"]{2,})$')])
    });
  }

  ngOnInit(): void {
    this.getBaseUrl().then(() => {
      this.route.queryParams.subscribe({
        next: (params) => {
          // get the query parameters
          if (params && params.tid) {
            this.qptid = +params.tid;
          } else {
            this.qptid = null;
          }
          if (params && params.spid) {
            this.qpspid = +params.spid;
          } else {
            this.qpspid = null;
          }
          if (params && params.pid) {
            this.qppid = +params.pid;
          } else {
            this.qppid = null;
          }
          this.uForm.controls.email.disable();
          this.onChanges();
        }
      });
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  getBaseUrl() {
    return new Promise((resolve, reject) => {
      switch (true) {
        case environment.environmentName === 'dev':
        case environment.environmentName === 'staging':
        case environment.environmentName === 'demo':
          this.environmentsService.getEnvironment(environment.environmentName).then((rtnTenant) => {
            if (rtnTenant) {
              this.setUrl(rtnTenant);
            }
          });
          break;
        case environment.local
        && environment.environmentName !== 'dev'
        && environment.environmentName !== 'demo'
        && environment.environmentName !== 'staging':
          if (this.tenant) {
            this.environmentsService.getEnvironment(`${this.tenant.toLowerCase()}-${environment.environmentName}`).then((rtnTenant) => {
              if (rtnTenant) {
                this.setUrl(rtnTenant);
              }
            });
          }
          break;
        case !environment.local:
          this.environmentsService.getEnvironment(window.location.hostname).then((rtnTenant) => {
            if (rtnTenant) {
              this.setUrl(rtnTenant);
            }
          });
          break;
      }
      // if(environment.environmentName === 'demo') {
      //   this.environmentsService.getEnvironment('demo').then((rtnTenant) => {
      //     if (rtnTenant) {
      //       this.setUrl(rtnTenant);
      //     }
      //   });
      // } else if(environment.environmentName === 'dev') {
      //   this.environmentsService.getEnvironment('dev').then((rtnTenant) => {
      //     if (rtnTenant) {
      //       this.setUrl(rtnTenant);
      //     }
      //   });
      // } else if(environment.local && environment.environmentName !== 'dev' && environment.environmentName !== 'demo') {
      //   this.environmentsService.getEnvironment(`${this.tenant.toLowerCase()}-${environment.environmentName}`).then((rtnTenant) => {
      //     if(rtnTenant) {
      //       this.setUrl(rtnTenant);
      //     }
      //   });
      // } else if(!environment.local) {
      //   this.environmentsService.getEnvironment(window.location.hostname).then((rtnTenant) => {
      //     if (rtnTenant) {
      //       this.setUrl(rtnTenant);
      //     }
      //   });
      // }
      resolve(null);
    });
  }

  setUrl(rtnTenant) {
    const context = {} as ContextModel;
    context.apiBaseUrlV1 = rtnTenant.apiBaseUrlV1;
    context.tenantName = rtnTenant.tenantEnum;
    context.tenantLogo = rtnTenant.tenantLogo;
    context.accessToken = null;
    context.multiTenant = rtnTenant.multiTenant;

    this.titleService.setTitle(rtnTenant.pageTitle);
    sessionStorage.setItem('pageTitle', rtnTenant.pageTitle);
    this.contextService.contextObject = context;
    this.logo = this.contextService.contextObject.tenantLogo;
  }

  onChanges(): void {
    this.uForm.controls.confirm.valueChanges.subscribe({
      next: (val) => {
        if (val) {
          this.uForm.controls.email.enable();
        } else {
          this.uForm.controls.email.disable();
        }
      }
    });
  }

  unsubscribe() {
    this.isLoading = true;
    this.userAccountUnsubscribeModel = {
      Tid: this.qptid,
      Spid: this.qpspid,
      Pid: this.qppid,
      VerifyEmailAddress: this.uForm.get('email').value
    };
    this.userAccountService.unsubscribe(this.userAccountUnsubscribeModel)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success', summary: 'Success',
            detail: 'You have been successfully unsubscribed and will no longer receive communications as requested. Have a nice day.', life: 3500
          });
          setTimeout(() => {
            this.router.navigate(['login']);
          }, 4000);
        }, error: (e) => {
          if (e?.error?.Message) {
            this.messageService.add({severity: 'error', summary: 'Unsubscribe Failed', detail: e.error.Message, life: 3500});
          } else {
            this.messageService.add({severity: 'error', summary: 'Unsubscribe Failed', detail: 'The unsubscribe failed. Please try again.', life: 3500});
          }
          this.isLoading = false;
        }
      });
  }
}
