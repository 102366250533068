@if (!loggedIn) {
  <div class="public-card">
    @if (domainFound) {
      <p-card class="card" [styleClass]="'login-card'">
        <div class="login-main-logo">
          <img class="img-width100" ngSrc="assets/images/{{logo}}" alt="" width="286" height="76" [priority]="true"/>
        </div>
        @if (!needHelp) {
          <form [formGroup]="lForm" class="login-form">
            <div class="p-fieldset-content">
              <label>Username (E-mail)<span class="ut-requireddenotation"> *</span></label>
              <input type="email" pInputText formControlName="name" (keyup.enter)="login()">
              @if (lForm.controls.name.invalid && lForm.controls.name.dirty) {
                <div class="p-error">
                  @if (lForm.controls.name.errors?.required) {
                    <div>Username is required</div>
                  }
                  @if (lForm.controls.name?.errors) {
                    <div>Username must be a valid e-mail address</div>
                  }
                </div>
              }
            </div>
            <div class="p-fieldset-content">
              <label>Password/Passphrase<span class="ut-requireddenotation"> *</span></label>
              <input [type]="passwordType" pInputText formControlName="password" (keyup.enter)="login()">
              @if (lForm.controls.password.invalid && lForm.controls.password.dirty) {
                <div class="p-error">
                  @if (lForm.controls.password.errors?.required) {
                    <div>Password is required</div>
                  }
                  <!--                <div *ngIf="changePasswordForm.controls.password.errors?.pattern">Must contain at least one uppercase and lowercase letter, one number, one special character, and be at least 10 characters long</div>-->
                  @if (lForm.controls.password.hasError('length')) {
                    <div>Must be at least 10 characters long</div>
                  }
                  @if (lForm.controls.password.hasError('uppercase')) {
                    <div>Must contain at least one uppercase letter (A-Z)</div>
                  }
                  @if (lForm.controls.password.hasError('lowercase')) {
                    <div>Must contain at least one lowercase letter (a-z)</div>
                  }
                  @if (lForm.controls.password.hasError('number')) {
                    <div>Must contain at least one number (0-9)</div>
                  }
                  @if (lForm.controls.password.hasError('special')) {
                    <div [innerHTML]="'Must contain at least one special character ($@#!%*?&)'"></div>
                  }
                  @if (lForm.controls.password.hasError('repeats')) {
                    <div>Must have no more than 2 identical characters in a row (999 not allowed)</div>
                  }
                  @if (lForm.controls.password.hasError('customValidator')) {
                    <div>No parts of your username</div>
                  }
                </div>
              }
              <div class="checkbox-input margin-top-5">
                <p-checkbox class="checkbox" [binary]="true" formControlName="showPassword"></p-checkbox>
                <label>Show Password</label>
              </div>
            </div>
            @if (showMultiTenant) {
              <div class="p-fieldset-content">
                <app-select class="full-width" [inputObj]="inputObjTenant"
                            (emitData)="setTenantData($event)"></app-select>
              </div>
            }
            @if (resetButton) {
              <div class="align-center">
                @if (!isLoading) {
                  <button pButton type="button" label="SIGN IN" (click)="login()" [disabled]="isFormValid()"></button>
                } @else {
                  <button pButton type="button" label="SIGN IN" [disabled]="true">
                    <i class="pi pi-spin pi-spinner save-spinner"></i>
                  </button>
                }
              </div>
            }
            <div>
              <span class="half checkbox-input margin-top-5">
                <p-checkbox class="checkbox" [binary]="true" formControlName="rememberMe"></p-checkbox>
                <label>Remember Me</label>
              </span>
              <span class="align-right half">
                <span class="link" (click)="needHelp=true">Need help?</span>
              </span>
            </div>
          </form>
        } @else {
          <div>
            <h2>Trouble logging in?</h2>
            <p>Here’s the info to get you back in to your account as quickly as possible.</p>
            <p>First, try the easiest thing: if you remember your password/passphrase but it isn’t working, make sure
              that Caps Lock is turned off, and that your username is spelled correctly, and then try again.</p>
            <p>If your password/passphrase still isn’t working, it may be time to <span class="showlink"
                                                                                        [routerLink]="['/public/password-reset-request']"
                                                                                        routerLinkActive="router-link-active">reset your password/passphrase</span>.
              We recommend using a <a class="ut-appnavigation" href="https://www.useapassphrase.com/" target="_blank"
                                      rel="noopener noreferrer" title="Why use a passphrase?">passphrase</a> instead of
              a password.</p>
            <p>Your account may also be temporarily locked due to several failed login attempts. Please check your inbox
              for more information from us.</p>
            <p>If you are still stuck, <a href="mailto:engagesupport@uniontrack.com?subject=Login Help Requested">contact
              us</a>.</p>
            <p><span class="link" (click)="needHelp=false">&lt; Back to Login</span></p>
          </div>
        }
      </p-card>
    }
    @if (domainFound) {
      @if (showSignUp) {
        <div class="align-center">
          <button pButton type="button" class="signup-button" label="New User? - Sign Up"
                  [routerLink]="['/public/user-registration']"></button>
        </div>
      }
    } @else {
      <p-card class="card" [styleClass]="'login-card'">
        <div class="login-main-logo">
          <img class="img-width100" ngSrc="assets/images/{{logo}}" alt="" width="286" height="76" [priority]="true"/>
        </div>
        <div class="p-fieldset-content">
          <p>UnionTrack&reg; Engage is not currently available at this domain. Please <a
            href="mailto:engagesupport@uniontrack.com?subject=Domain Unrecognized">contact us</a> for more information.
          </p>
          <p>Have a great day.</p>
        </div>
      </p-card>
    }
  </div>
}
<p-toast [baseZIndex]="16000"></p-toast>
