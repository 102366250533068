/* eslint-disable @typescript-eslint/quotes */
import {AfterViewInit, ChangeDetectorRef, Component, HostListener, Inject, isDevMode, Renderer2} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {SidenavService} from './core/services/sidenave-service';
import {AuthService} from './core/services/auth.service';
import {Subject} from 'rxjs';
import {ConfirmationService} from 'primeng/api';
import {ContextModel} from './models/context.model';
import {ContextService} from './services/context.service';
import {filter} from 'rxjs/operators';
import {environment} from '../environments/environment';
import {Title} from '@angular/platform-browser';
import {DOCUMENT} from '@angular/common';
import {ExemptUsersDefinitions} from './shared/definitions/exempt-users.definitions';
import {EnvironmentsService} from './services/environments.service';
import {SharedImports} from '@shared/shared-imports';
import {SidebarModule} from 'primeng/sidebar';
import {UserAccountService} from '@services/user-account.service';
import {SystemService} from '@services/system.service';
import {UserProfileService} from '@services/user-profile.service';
import {HeaderComponent} from '@app/core/components/header/header.component';
import {SideNavComponent} from '@app/core/components/side-nav/side-nav.component';
import {HeaderNavComponent} from '@app/core/components/header-nav/header-nav.component';
import {AlertsComponent} from '@app/core/components/alerts/alerts.component';
import {ToastModule} from 'primeng/toast';
import {DialogModule} from 'primeng/dialog';

declare const gtag;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
     standalone: true,
  imports: [SharedImports, SidebarModule, HeaderComponent, SideNavComponent, HeaderNavComponent, AlertsComponent, ToastModule, DialogModule],
  providers:[UserAccountService, AuthService, SystemService, UserProfileService, AlertsComponent, ConfirmationService]
})

export class AppComponent implements AfterViewInit {
  isAuthenticated: boolean = false;
  copyYear: number = new Date().getFullYear();
  opened: boolean = false;
  openedAlerts: boolean = false;
  topNav: boolean = true;
  pushMode: 'over' | 'push' | 'slide';
  tenant: string;
  context = new ContextModel();
  ShowMenuSelect: boolean = true;
  parentShowBurger: boolean = false;
  isAlertsOpened: boolean = false;
  userActivity;
  userInactive: Subject<any> = new Subject();
  selectedMenuView: string;
  dataPerspectiveDescription: string = null;
  isPublic: boolean;
  isMobile: boolean = false;
  toastSave: boolean = false;
  timer;
  public _environment = environment;
  showPrompt: string = 'false';
  userAgreement: boolean = false;
  //mobile
  deferredPrompt: any;
  showButton = false;
  private pushed: boolean = false;

  constructor(public router: Router, public sidenavService: SidenavService, private confirmationService: ConfirmationService,
              private authService: AuthService, public contextService: ContextService, private changeDetection: ChangeDetectorRef,
              private titleService: Title, public exemptUsersDefinitions: ExemptUsersDefinitions, private environmentsService: EnvironmentsService,
              @Inject(DOCUMENT) private document: Document, private _renderer2: Renderer2,) {


    if (this.iOS()) {
      this.isMobile = true;
    }

    if(!isDevMode() && sessionStorage.getItem('gaTag') !== 'null') {
      this.loadGoogleAnalytics(sessionStorage.getItem('gaTag'));
      this.router.events.subscribe({next: (event:any) => {
        if (event instanceof NavigationEnd) {
          gtag('config', sessionStorage.getItem('gaTag'), { page_path: event.urlAfterRedirects });
        }
      }});
    }

    this.loadTheme();
    const context = {} as ContextModel;
    if (!contextService.contextObject) {
      context.apiBaseUrlV1 = null;
      context.tenantName = null;
      context.tenantLogo = null;
      context.supportEmail = null;
      context.accessToken = null;
      context.isLoggedIn = false;
      context.isMobile = this.isMobile;
      if (this.isMobile) {
        context.visibility = 'native';
      } else {
        context.visibility = 'hover';
      }
      this.contextService.contextObject = context;
    } else {
      context.isMobile = false;
    }

    // console.log(this.isMobile);

    router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe({
        next: (e: NavigationEnd) => {
          if (!e.url.includes('/login')) {
            this.userAgreement = this.contextService.contextObject.UserAgreement;
            if (e.url.includes('/membership/')) {
              this.contextService.setContextPerspective(['MEMBERSHIP']);
            } else if (e.url.includes('/training/')) {
              this.contextService.setContextPerspective(['TRAINING']);
            } else if (e.url.includes('/organize/')) {
              this.contextService.setContextPerspective(['ORGANIZE']);
            }
            this.dataPerspectiveDescription = this.contextService.contextObject.selOrganizationDesc;
          }
          this.isPublic = e.url.includes('/public/');
        }
      });

    this.sidenavService.sidenav$.subscribe({
      next: (res) => {
        this.opened = res;
        this.pushed = this.opened;
      }
    });

    this.sidenavService.sideAlert$.subscribe({
      next: (res) => {
        this.isAlertsOpened = res;
      }
    });

    this.contextService.context$.subscribe({
      next: (res) => {
        this.dataPerspectiveDescription = res;
      }
    });

    this.authService.userEmitter.subscribe({
      next: (token) => {
        this.isAuthenticated = token;
      }
    });

    this.setTimeout();
    this.userInactive.subscribe({
      next: () => {
        this.logout();
      }
    });

    this.titleService.setTitle(sessionStorage.getItem('pageTitle'));

    if (sessionStorage.getItem('newRelic')) {
      this.loadNewRelic();
    }
  }

  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e) {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    this.deferredPrompt = e;
    this.showButton = true;
  }

  iOS() {
    return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
  }

  loadGoogleAnalytics(trackingID: string): void {
    const gaScript = document.createElement('script');
    gaScript.setAttribute('async', 'true');
    gaScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${ trackingID }`);

    const gaScript2 = document.createElement('script');
    gaScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${ trackingID }');`;
    document.documentElement.firstChild.appendChild(gaScript);
    document.documentElement.firstChild.appendChild(gaScript2);
  }

  loadNewRelic() {
    const script = this._renderer2.createElement('script');
    script.src = sessionStorage.getItem('newRelic');
    this._renderer2.appendChild(this.document.head, script);
  }

  ngAfterViewInit(): void {
    if (this.contextService.contextObject.isLoggedIn) {
      this.isAuthenticated = true;
      this.dataPerspectiveDescription = this.contextService.contextObject.selOrganizationDesc;
      this.setReponsive(window.innerWidth);
    } else {
      this.isAuthenticated = false;
    }
    this.changeDetection.detectChanges();
  }

  menuSelect(menu): void {
    if (menu === 'top') {
      this.topNav = true;
      this.opened = false;
    } else {
      this.topNav = false;
    }
  }

  menuViewSelect(menuView): void {
    this.selectedMenuView = menuView;
  }

  closeAlerts(): void {
    this.isAlertsOpened = false;
  }

  setTimeout(): void {
    if (this.isAuthenticated) {
      this.userActivity = setTimeout(() => {
        // capture the current route prior to going idle
        localStorage.setItem('redirect', this.router.url.replace('/', ''));
        // go idle
        this.userInactive.next(undefined);
      }, 1800000);
    }
  }

  @HostListener('window:mousemove') refreshUserState(): void {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }

  @HostListener('window:resize', ['$event']) onResize(event:any): void {
    this.setReponsive(event.target.innerWidth);
  }

  setReponsive(width): void {
    if (width < 769 || this.isMobile) {
      this.setMobile();
    } else {
      this.pushMode = 'push';
      this.topNav = true;
      this.ShowMenuSelect = true;
      this.parentShowBurger = false;
      const contextObject: ContextModel = this.contextService.contextObject;
      contextObject.isMobile = false;
      this.contextService.contextObject = contextObject;
    }

  }

  setMobile(): void {
    this.pushMode = 'over';
    this.menuSelect('side');
    this.ShowMenuSelect = false;
    this.parentShowBurger = true;
    const contextObject: ContextModel = this.contextService.contextObject;
    contextObject.isMobile = true;
    this.contextService.contextObject = contextObject;
    this.isMobile = true;
  }

  logout(): void {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      this.confirmationService.close();
      this.authService.logout();
    }, 30000);
    this.confirmationService.confirm({
      message: 'Due to inactivity, you will be logged off in 30 seconds. Do you wish to stay logged in?',
      accept: () => {
        clearTimeout(this.timer);
        this.setTimeout();
      },
      reject: () => {
        this.confirmationService.close();
        this.authService.logout();
      }
    });
  }

  loadTheme() {
    if (sessionStorage.getItem('customCSS')) {
      const cssFile = sessionStorage.getItem('customCSS');
      const headEl = this.document.getElementsByTagName('head')[0];
      const newLinkEl = this.document.createElement('link');
      newLinkEl.rel = 'stylesheet';
      newLinkEl.href = cssFile + '.css';
      sessionStorage.setItem('customCSS', cssFile);
      headEl.append(newLinkEl);
    }
  }
}
