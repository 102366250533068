import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {ContextService} from './context.service';
import {FreshdeskTicketCreateModel} from '@app/models/freshdesk-ticket.model';

@Injectable({
  providedIn: 'root',
})
export class FreshdeskService {
  constructor(private http: HttpClient, private contextService: ContextService) {
  }


  createTicket(data): Observable<FreshdeskTicketCreateModel> {
    return this.http.post<FreshdeskTicketCreateModel>('https://uniontrack.freshdesk.com/api/v2/tickets', data,
      {
        headers: new HttpHeaders({
          Authorization: 'Basic d3ZveFlmVHhOckxhSDZ4YVBJSFE6dGVzdA==', 'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
        })
      });
  }

  // createTicket(dataModel: FreshdeskTicketCreateModel,): Observable<FreshdeskTicketCreateModel> {
  //   return this.http.post<FreshdeskTicketCreateModel>(`${this.contextService.contextObject.apiBaseUrlV2}/client/freshdesk`, dataModel);
  // }
}
