import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {ContextService} from './context.service';
import {
  CommunicationsGridModel,
  CommunicationModel,
  CommunicationStatsModel,
  CommunicationAvailableSendersModel,
  UpdateCommunication,
  UpdateCommunicationExcludesModel,
  CreateCommunicationCCModel,
  CommunicationDistributionsGridModel,
  SendAdHocCommunicationModel,
  SendAdHocCommunicationDBEntityModel,
  SendAnonymousCommunicationModel,
  CommunicationDistributionCountModel
} from '@app/models/communications.model';
import {Userpilot} from 'userpilot';

@Injectable({
  providedIn: 'root',
})

export class CommunicationsService {
  constructor(private http: HttpClient, private contextService: ContextService) {
  }

  getCommunicationsPrimeNG(dataMode: string, context: string, filterData, orgId?: number): Observable<CommunicationsGridModel> {
    Userpilot.track('Communications List');
    orgId = orgId !== undefined ? orgId : 0;
    return this.http.post<CommunicationsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/communication/grid/${orgId}?context=${context}&mode=${dataMode}`, filterData);
  }

  createCommunication(context: string): Observable<CommunicationModel> {
    // Create and copy share the same api, just pass a 0 to create
    return this.http.post<CommunicationModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/communication/create/0?context=${context}`, null);
  }

  copyCommunication(communicationId: number, context: string): Observable<CommunicationModel> {
    // Create and copy share the same api, just pass an existing communication id to copy
    return this.http.post<CommunicationModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/communication/create/${communicationId}?context=${context}`, null);
  }

  deleteCommunication(communicationId: number): Observable<any> {
    return this.http.delete<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/communication/remove/${communicationId}`);
  }

  getCommunicationStats(communicationId: number): Observable<CommunicationStatsModel[]> {
    return this.http.get<CommunicationStatsModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/communication/stats/${communicationId}`);
  }

  getCommunicationDetails(communicationId: number): Observable<CommunicationModel> {
    return this.http.get<CommunicationModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/communication/details/${communicationId}`);
  }

  getCommunicationAvailableSenders(communicationId: number): Observable<CommunicationAvailableSendersModel[]> {
    return this.http.get<CommunicationAvailableSendersModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/communication/${communicationId}/availablesenders`);
  }

  updateCommunication(communicationId: number, modelData: UpdateCommunication): Observable<string> {
    return this.http.put<string>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/communication/details/${communicationId}`, modelData);
  }

  updateCommunicationExcludes(communicationId: number, modelData: UpdateCommunicationExcludesModel[]): Observable<string> {
    return this.http.put<string>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/communication/exclude/${communicationId}`, modelData);
  }

  createCommunicationCCs(communicationId: number, modelData: CreateCommunicationCCModel): Observable<string> {
    return this.http.put<string>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/communication/${communicationId}/addcc`, modelData);
  }

  getCommunicationDistributionsPrimeNG(dataMode: string, communicationId: number, filterData): Observable<CommunicationDistributionsGridModel> {
    return this.http.post<CommunicationDistributionsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/communication/distribution/${communicationId}?mode=${dataMode}`, filterData);
  }

  getCommunicationDistributionCounts(communicationId: number): Observable<CommunicationDistributionCountModel> {
    return this.http.get<CommunicationDistributionCountModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/communication/${communicationId}/distributioncounts`);
  }

  sendCommunication(communicationId: number): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/communication/sendpublish/${communicationId}`, null);
  }

  sendCommunicationTest(communicationId: number): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/communication/${communicationId}/sendtest`, null);
  }

  sendCommunicationAdmin(communicationId: number): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/communication/adminsendpublish/${communicationId}`, null);
  }

  resendCommunication(communicationId: number): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/communication/resend/${communicationId}`, null);
  }

  resendCommunicationAsync(communicationId: number): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/communication/resendasync/${communicationId}`, null);
  }

  scheduleCommunicationDistribution(communicationId: number, dateTime: string): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/communication/${communicationId}/schedule?utcstartdatetime=${dateTime}`, null);
  }

  unscheduleCommunicationDistribution(communicationId: number): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/communication/${communicationId}/unschedule`, null);
  }

  sendCommunicationAdHoc(modelData: SendAdHocCommunicationModel): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/communication/adhoc`, modelData);
  }

  sendCommunicationAdHocDBEntity(modelData: SendAdHocCommunicationDBEntityModel): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/communication/adhoc`, modelData);
  }

  getCommunicationByEntityPrimeNG(dataMode: string, entity: string, entityKeyId: number, context: string, filterData): Observable<CommunicationsGridModel> {
    return this.http.post<CommunicationsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/communication/grid/byentity/${entity}/${entityKeyId}?context=${context}&mode=${dataMode}`, filterData);
  }

  sendAnonymousEmail(modelData: SendAnonymousCommunicationModel): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/common/anonymousemail`, modelData);
  }
}
