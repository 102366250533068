import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {ContextService} from './context.service';

import {AttributeByEntityGridModel, AttributeByEntityModel, AttributeModel, AttributesGridModel, AttributesValueByEntityGridModel, AttributeValueByEntityModel, CreateUpdateAttributeValueModel, UpdateAttributeModel} from '@app/models/attributes.model';
import {Userpilot} from 'userpilot';

@Injectable({
  providedIn: 'root',
})
export class AttributesService {
  constructor(private http: HttpClient, private contextService: ContextService) {
  }

  getEntityAttributesKGrid(entity: string, responseEntity?: string): Observable<AttributesGridModel> {
    const url = (responseEntity) ? `?responseEntity=${responseEntity}` : '';
    return this.http.get<AttributesGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/attribute/orgreference/${entity}/attributegrid${url}`);
  }

  getAttribute(attributeId: number): Observable<AttributeModel> {
    return this.http.get<AttributeModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/attribute/${attributeId}`);
  }

  getAttributeValueCount(attributeId: number): Observable<number> {
    return this.http.get<number>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/attribute/${attributeId}/valuecount`);
  }

  createOrganizationReferenceAttribute(organizationReferenceId: number, modelData: UpdateAttributeModel): Observable<number> {
    return this.http.post<number>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/attribute/orgreference/${organizationReferenceId}/createattribute`, modelData);
  }

  updateAttribute(attributeId: number, modelData: UpdateAttributeModel): Observable<AttributeModel> {
    return this.http.put<AttributeModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/attribute/${attributeId}`, modelData);
  }

  deleteAttribute(organizationReferenceId: number, attributeId: number): Observable<any> {
    return this.http.delete<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/attribute/orgreference/${organizationReferenceId}/removeattribute/${attributeId}`);
  }

  reorderAttribute(attributeId1: number, attributeId2: number): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/attribute/reorder?id1=${attributeId1}&id2=${attributeId2}`, null);
  }

  getOrganizationReferenceAttributesPrimeNG(dataMode: string, organizationReferenceId: number, filterData): Observable<AttributesGridModel> {
    Userpilot.track('Custom Fields List');
    return this.http.post<AttributesGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/attribute/orgreference/${organizationReferenceId}/attributegrid?mode=${dataMode}`, filterData);
  }

  getPersonValuePrimeNG(dataMode: string, personId: number, category: string, filterData): Observable<AttributesValueByEntityGridModel> {
    return this.http.post<AttributesValueByEntityGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/attribute/person/${personId}/valuegrid?category=${category}&mode=${dataMode}`, filterData);
  }

  getOrganizationReferenceValuePrimeNG(dataMode: string, organizationReferenceId: number, category: string, filterData): Observable<AttributesValueByEntityGridModel> {
    return this.http.post<AttributesValueByEntityGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/attribute/orgreference/${organizationReferenceId}/valuegrid?mode=${dataMode}&category=${category}`, filterData);
  }

  getPersonAvailableAttributes(personId: number, category: string): Observable<AttributeByEntityModel[]> {
    return this.http.get<AttributeByEntityModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/attribute/person/${personId}/availableattributes?category=${category}`);
  }

  getOrganizationReferenceAvailableAttributes(organizationReferenceId: number, category: string, responseentity?: string): Observable<AttributeByEntityModel[]> {
    const url = (responseentity) ? `&responseentity=${responseentity}` : '';
    return this.http.get<AttributeByEntityModel[]>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/attribute/orgreference/${organizationReferenceId}/availableattributes?category=${category}${url}`);
  }

  getAttributeValue(valueId: number): Observable<AttributeValueByEntityModel> {
    return this.http.get<AttributeValueByEntityModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/attribute/value/${valueId}`);
  }

  createPersonValue(personId: number, modelData: CreateUpdateAttributeValueModel): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/attribute/person/${personId}/createvalue`, modelData);
  }

  createOrganizationReferenceValue(organizationReferenceId: number, modelData: CreateUpdateAttributeValueModel): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/attribute/orgreference/${organizationReferenceId}/createvalue`, modelData);
  }

  updateAttributeValue(valueId: number, modelData: CreateUpdateAttributeValueModel): Observable<any> {
    return this.http.put<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/attribute/value/${valueId}`, modelData);
  }

  deleteAttributeValue(valueId: number): Observable<any> {
    return this.http.delete<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/attribute/removevalue/${valueId}`);
  }

  getRosterAvailableAttributesKGrid(category: string): Observable<AttributeByEntityGridModel[]> {
    return this.http.get<AttributeByEntityGridModel[]>(
      `${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/roster/attributelist?organizationcategory=${category}`);
  }

  getOrgAvailableAttributes(category: string, responseEntity: string): Observable<AttributeByEntityGridModel[]> {
    if (responseEntity) {
      return this.http.get<AttributeByEntityGridModel[]>(
        `${this.contextService.contextObject.apiBaseUrlV1}/api/1.0//organization/attributelist?organizationcategory=${category}&responseEntity=${responseEntity}`);
    } else {
      return this.http.get<AttributeByEntityGridModel[]>(
        `${this.contextService.contextObject.apiBaseUrlV1}/api/1.0//organization/attributelist?organizationcategory=${category}`);
    }

  }


}
