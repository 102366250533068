import {AfterViewInit, Directive} from '@angular/core';
import {Calendar} from 'primeng/calendar';
import Inputmask from 'inputmask';

@Directive({
    selector: '[dateMask]',
})
export class DateMaskDirective implements AfterViewInit {
    constructor(private primeCalendar: Calendar) { }

    ngAfterViewInit(): void {
        const calendar = this.getHTMLInput();
        const im = new Inputmask( this.getDateMask() );
        im.mask(calendar);
    }

    getHTMLInput(): HTMLInputElement {
        return this.primeCalendar.el.nativeElement.querySelector('input');
    }

    getDateMask(): string {
        if(this.primeCalendar.timeOnly) {
            return '99:99';
        } else if(this.primeCalendar.showTime) {
            return '99/99/9999 99:99';
        } else {
            return '99/99/9999';
        }
    }
}
