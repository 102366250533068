import {Injectable} from '@angular/core';
import {TenantConfigDataService} from '@services/tenant-config-data.service';
import {AttributeSelectModel} from '@app/models/roster-query-primeng.model';
import {activityDrivenMembers, employmentTradeTab} from '@shared/utilities/component-manager-utility';

@Injectable()
export class DistributionListDefinitions {
  public rosterStudentCols: any[];
  public studentRosterContactCols: any[];
  public studentRosterDemographicsCols: any[];
  public studentRosterMembershipCol: any[];
  public studentRosterProgramsCol: any[];
  public studentRosterStudentCol: any[];
  public studentClassesCol: any[];
  public studentCertificationsCol: any[];
  public studentEmploymentCol: any[];
  public studentTradesCol: any[];
  public studentSkillsCol: any[];
  public studentCustomFieldsCols: any[];
  public studentNotesCols: any[];
  public secureDataCols: any[];
  public emergencyContactsCols: any[];
  public wageRatesCols: any[];
  public rosterCols: any[];
  public prospectCols: any[];
  public caseCols: any[];
  public positionCols: any[];
  public projectReportsCols: any[];
  public employersCols: any[];
  // public studentPositionCols: any[];
  public employmentCols: any[];
  public organizationEmployerCols: any[];
  public voterCols: any[];
  public programCols: any[];
  public classCols: any[];
  public communicationCols: any[];
  public certificationCols: any[];
  public organizationCol: any[];
  public memberTableOptions: any[];
  public organizationTableOptions: any[];
  public studentTableOptions: any[];
  public communicationTableOptions: any[];
  public organizeTableOptions: any[];
  public projectReportsTableOptions: any[];
  public prospectsTableOptions: any[];
  public matchTextTypes: any[];
  public matchNumberTypes: any[];
  public matchDateTypes: any[];
  public matchPickerTypes: any[];
  public booleanValues: any[];
  public yesNoValues: any[];
  public sendPublishStatusValues: any[];
  public employerTableOptions: any[];
  public attributesOptions: AttributeSelectModel [] = [];
  public _activityDrivenMembers = activityDrivenMembers;
  public _employmentTradeTab = employmentTradeTab;

  constructor(private tenantConfigDataService: TenantConfigDataService) {
    const STATEMENTBILLING: boolean = this.tenantConfigDataService.getBooleanValue('STATEMENTBILLING');
    const STRINGREPLACELOCAL: string = this.tenantConfigDataService.getStringValue('STRINGREPLACELOCAL');
    const STRINGREPLACEOCCUPATION = this.tenantConfigDataService.getStringValue('STRINGREPLACEOCCUPATION');
    const STRINGREPLACECLASSIFICATION: string = this.tenantConfigDataService.getStringValue('STRINGREPLACECLASSIFICATION');
    const STRINGREPLACETYPE: string = this.tenantConfigDataService.getStringValue('STRINGREPLACETYPE');

    const STRINGREPLACEFILEDDATE: string = this.tenantConfigDataService.getStringValue('STRINGREPLACEFILEDDATE');
    const STRINGREPLACEEMPLOYERIDENTIFIER: string = this.tenantConfigDataService.getStringValue('STRINGREPLACEEMPLOYERIDENTIFIER');
    const STRINGREPLACEUNIONIDENTIFIER: string = this.tenantConfigDataService.getStringValue('STRINGREPLACEUNIONIDENTIFIER');
    const CASEGRIDCUSTOMSTRING1: string = this.tenantConfigDataService.getStringValue('CASEGRIDCUSTOMSTRING1');
    const CASEGRIDCUSTOMSTRING2: string = this.tenantConfigDataService.getStringValue('CASEGRIDCUSTOMSTRING2');
    const CASEGRIDCUSTOMSTRING3: string = this.tenantConfigDataService.getStringValue('CASEGRIDCUSTOMSTRING3');
    const CASEGRIDCUSTOMSTRING4: string = this.tenantConfigDataService.getStringValue('CASEGRIDCUSTOMSTRING4');
    const CASEGRIDCUSTOMSTRING5: string = this.tenantConfigDataService.getStringValue('CASEGRIDCUSTOMSTRING5');
    const CASEGRIDCUSTOMSTRING6: string = this.tenantConfigDataService.getStringValue('CASEGRIDCUSTOMSTRING6');

    this.memberTableOptions = [
      {option: 'Roster', value: 'ROSTER'},
      {option: 'Position', value: 'UNIONPOSITION'},
      {option: 'Employment', value: 'EMPLOYMENT'},
      {option: 'Voter', value: 'VOTER'},
      {option: 'Survey', value: 'SURVEYRESPONSE'},
      {option: 'Custom Fields', value: 'ATTRIBUTE'},
      {option: 'Communication', value: 'COMMUNICATIONSTATUS'}
    ];

    this.studentTableOptions = [
      {option: 'Student Roster', value: 'STUDENT'},
      {option: 'Position', value: 'EDUCATIONPOSITION'},
      {option: 'Employment', value: 'EMPLOYMENT'},
      {option: 'Classes', value: 'STUDENTCLASS'},
      {option: 'Programs', value: 'STUDENTPROGRAM'},
      {option: 'Voter', value: 'VOTER'},
      {option: 'Survey', value: 'SURVEYRESPONSE'},
      {option: 'Custom Fields', value: 'STUDENTATTRIBUTE'},
      {option: 'Communication', value: 'COMMUNICATIONSTATUS'},
      {option: 'Certifications', value: 'CERTIFICATION'}
    ];

    this.organizeTableOptions = [
      {option: 'Prospects', value: 'PROSPECT'}
      //{option : 'Position', value: 'EDUCATIONPOSITION'},
      // {option : 'Custom Fields', value: 'STUDENTATTRIBUTE'},
      // {option : 'Communication', value: 'COMMUNICATIONSTATUS'}
    ];

    this.projectReportsTableOptions = [
      {option: 'Project Reports', value: 'PROJECT'},
      {option: 'Employers', value: 'PROJECTEMPLOYER'}
    ];

    this.prospectsTableOptions = [
      {option: 'Project Reports', value: 'PROSPECT'},
      {option: 'Employers', value: 'EDUCATIONPOSITION'}
    ];

    this.communicationTableOptions = [
      {option: 'Communication', value: 'COMMUNICATIONSTATUS'}
    ];

    this.organizationTableOptions = [
      {option: 'Organizations', value: 'ORGANIZATION'},
      {option: 'Custom Fields', value: 'ORGATTRIBUTE'},
    ];

    this.employerTableOptions = [
      {option: 'Employer', value: 'EMPLOYER'},
      {option: 'Custom Fields', value: 'ORGATTRIBUTE'},
    ];

    this.matchTextTypes = [
      {option: 'Contains', value: 'contains'},
      {option: 'Equals', value: 'equals'},
      {option: 'Starts With', value: 'startsWith'},
      {option: 'Ends With', value: 'endsWith'},
      {option: 'Does not Equal', value: 'notequals'},
      {option: 'Is not empty', value: 'isnotnullorempty'},
      {option: 'Is Empty', value: 'isnullorempty'}
    ];

    this.matchPickerTypes = [
      {option: 'Equals', value: 'equals'},
      {option: 'Is not empty', value: 'isnotnullorempty'},
      {option: 'Is Empty', value: 'isnullorempty'}
    ];

    this.matchNumberTypes = [
      {option: 'Equals', value: 'equals'},
      {option: 'Greater Than', value: 'gt'},
      {option: 'Less Than', value: 'lt'},
      {option: 'Less Than or Equal', value: 'lte'},
      {option: 'Greater Than or Equal', value: 'gte'},
      {option: 'Does not Equal', value: 'notequals'},
      {option: 'Is not empty', value: 'isnotnullorempty'},
      {option: 'Is Empty', value: 'isnullorempty'},
      {option: 'Between', value: 'between'}
    ];

    this.matchDateTypes = [
      {option: 'Date is', value: 'dateis'},
      {option: 'Date is Not', value: 'dateisnot'},
      {option: 'Date is After', value: 'dateafter'},
      {option: 'Date is Before', value: 'datebefore'},
      {option: 'Between', value: 'between'},
      {option: 'Is not empty', value: 'isnotnullorempty'},
      {option: 'Is Empty', value: 'isnullorempty'},
      {option: 'Days from Present', value: 'daysfrompresent'},
      {option: 'Days from Present Between', value: 'daysfrompresentbetween'},
      {option: 'Months from Present Between', value: 'monthsfrompresentbetween'},
      {option: 'Month from Present', value: 'monthsfrompresent'}
    ];

    this.rosterStudentCols = [
      {field: 'LastName', header: 'Last Name', fullHeader: 'Last Name', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true, frozen: true, isID: false},
      {field: 'FirstName', header: 'First Name', fullHeader: 'First Name', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true, frozen: true, isID: false},
      {field: 'MiddleName', header: 'Middle Name', fullHeader: 'Middle Name', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: false, frozen: true, isID: false},
      {field: 'Prefix', header: 'Prefix', fullHeader: 'Prefix', columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: false, frozen: false, isID: false},
      {field: 'Gender', header: 'Gender', fullHeader: 'Gender', columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: false, frozen: false, isID: false},
      // {field: 'DOB_D', header: 'Birth Date', fullHeader: 'Birth Date', columnType: 'date', matchMode: 'equals', visible: true},
      {field: 'CardIssueDate', header: 'Card Iss. Date', fullHeader: 'Card Issue Date', columnType: 'date', displayType: 'text', matchMode: 'equals', visible: true},
      {field: 'Age', header: 'Age', fullHeader: 'Age', columnType: 'numeric', displayType: 'numeric', matchMode: 'equals', visible: true, frozen: false, isID: false},
      {field: 'StudentNumber', header: 'Student #', fullHeader: 'Student #', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: false, frozen: false, isID: false},
      {field: 'MemberNumber', header: 'Member #', fullHeader: 'Member #', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'MemberStatusAlias', header: 'Membership Status', fullHeader: 'Membership Status', columnType: 'picker', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'ProgramStatus', header: 'Program Status', fullHeader: 'Program Status', columnType: 'picker', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'MemberTypeAlias', header: 'Membership ' + STRINGREPLACETYPE, fullHeader: 'Membership ' + STRINGREPLACETYPE, columnType: 'picker', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'OrganizationName', header: STRINGREPLACELOCAL, fullHeader: STRINGREPLACELOCAL, columnType: 'picker', displayType: 'text', visible: true, frozen: false, isID: true},
      // {field: 'UTStatusDescription', header: 'Launch Status', fullHeader: 'Launch Status', columnType: 'text', matchMode: 'contains', visible: false},
      {field: 'StatusAlias', header: 'Student Status', fullHeader: 'Student Status', columnType: 'picker', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'ProgramTrainingCenterName', header: 'Training Center', fullHeader: 'Program Training Center', columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'ProgramDescription', header: 'Program Description', fullHeader: 'Program Description', columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'BargainingUnitDescription', header: 'Bargaining Unit', fullHeader: 'Bargaining Unit', columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: true, frozen: false, isID: true},
      {field: 'TypeAlias', header: 'Student Type', fullHeader: 'Training Type', columnType: 'picker', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'Classification', header: STRINGREPLACECLASSIFICATION, fullHeader: STRINGREPLACECLASSIFICATION, columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'Instructor', header: 'Instructor', fullHeader: 'Instructor', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'Race', header: 'Race/Ethnicity', fullHeader: 'Race/Ethnicity', columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: true, frozen: false, isID: false},
      // {field: 'Race', header: 'Race', fullHeader: 'Race', columnType: 'picker', matchMode: 'equals', visible: false, frozen: false, isID: false},
      {field: 'Language', header: 'Language', fullHeader: 'Language', columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'PreferredLanguage', header: 'Preferred Language', fullHeader: 'Preferred Language', columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'Education', header: 'Education', fullHeader: 'Education', columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: false, frozen: false, isID: false},
      {field: 'Military', header: 'Military Branch', fullHeader: 'Military Branch', columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'MilitaryStatus', header: 'Military Status', fullHeader: 'Military Status', columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'StudentGroupID', header: 'Student Group', fullHeader: 'Student Group', columnType: 'picker', matchMode: 'contains', displayType: 'text', visible: true, isID: true},
      {field: 'StudentGroupAlias', header: 'Student Group Alias', fullHeader: 'Student Group Alias', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: false},
      {field: 'PrimaryAddress1', header: 'Street Address', fullHeader: 'Street Address', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: false, frozen: false, isID: false},
      {field: 'PrimaryAddress2', header: 'Ste, Bldg, Flr, etc.', fullHeader: 'Ste, Bldg, Flr, etc.', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: false, frozen: false, isID: false},
      {field: 'PrimaryCity', header: 'City/Town', fullHeader: 'City/Town', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: false, frozen: false, isID: false},
      {field: 'PrimaryStateProvince', header: 'State/Province', fullHeader: 'State/Province', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: false, frozen: false, isID: false},
      {field: 'PrimaryZip', header: 'Postal Code', fullHeader: 'Postal Code', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: false, frozen: false, isID: false},
      {field: 'PrimaryCounty', header: 'County', fullHeader: 'County', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: false, frozen: false, isID: false},
      {field: 'PrimaryCountry', header: 'Country', fullHeader: 'Country', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: false, frozen: false, isID: false},
      {field: 'PrimaryPhoneNumber', header: 'Home Phone #', fullHeader: 'Home Phone #', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: false, frozen: false, isID: false},
      {field: 'SecondaryPhoneNumber', header: 'Mobile Phone #', fullHeader: 'Mobile Phone #', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: false, frozen: false, isID: false},
      {field: 'ThirdPhoneNumber', header: 'Other Phone #', fullHeader: 'Other Phone #', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: false, frozen: false, isID: false},
      {field: 'PublicPhoneNumber', header: 'Public Phone #', fullHeader: 'Public Phone #', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: false, frozen: false, isID: false},
      {field: 'PrimaryEmailAddress', header: 'Primary E-mail', fullHeader: 'Primary E-mail', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: false, frozen: false, isID: false},
      {field: 'PrimaryEmailFailedCommunication', header: 'Primary E-mail Undeliverable', fullHeader: 'Primary E-mail Undeliverable', columnType: 'ctYESNO', matchMode: 'equals', displayType: 'text', visible: false, frozen: false, isID: false},
      {field: 'SecondaryEmailAddress', header: 'Business E-mail', fullHeader: 'Business E-mail', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: false, frozen: false, isID: false},
      {field: 'SecondaryEmailFailedCommunication', header: 'Secondary E-mail Undeliverable', fullHeader: 'Secondary E-mail Undeliverable', columnType: 'ctYESNO', matchMode: 'equals', displayType: 'text', visible: false, frozen: false, isID: false},
      {field: 'PublicEmailAddress', header: 'Public E-mail', fullHeader: 'Public E-mail', columnType: 'text', matchMode: 'contains', visible: false, displayType: 'text', frozen: false, isID: false},
      {field: 'PublicEmailFailedCommunication', header: 'Public E-mail Undeliverable', fullHeader: 'Public E-mail Undeliverable', columnType: 'ctYESNO', displayType: 'text', matchMode: 'equals', visible: false, frozen: false, isID: false},
      {field: 'SMSOptOut', header: 'SMS Opt Out', fullHeader: 'SMS Opt Out', columnType: 'ctYESNO', matchMode: 'equals', displayType: 'text', visible: false, frozen: false, isID: false},
      {field: 'PrimaryEmailOptOut', header: 'Primary E-mail Opt Out', fullHeader: 'Primary E-mail Opt Out', columnType: 'YESNO', matchMode: 'equals', displayType: 'text', visible: false, frozen: false, isID: false},
      {field: 'SecondaryEmailOptOut', header: 'Secondary E-mail Opt Out', fullHeader: 'Secondary E-mail Opt Out', columnType: 'YESNO', matchMode: 'equals', displayType: 'text', visible: false, frozen: false},
      {field: 'HasAccount', header: 'User Account', fullHeader: 'User Account', columnType: 'ctYESNO', matchMode: 'contains', displayType: 'text', visible: true, frozen: false, isID: false}
    ];
    if (this._employmentTradeTab() === true) {
      this.rosterStudentCols.push(
        {field: 'PrimaryTrade', header: 'Primary Trade', fullHeader: 'Primary Trade', columnType: 'picker', valueOption: 'Description', matchMode: 'equals', displayType: 'text', visible: true, frozen: false, isID: false},
        {field: 'PrimarySkill', header: 'Primary Skill', fullHeader: 'Primary Skill', columnType: 'picker', valueOption: 'Description', matchMode: 'equals', displayType: 'text', visible: true, frozen: false, isID: false}
      );
    }

    this.rosterCols = [
      // {field: 'ThumbnailUrl', header: 'Profile', fullHeader: 'Profile', columnType: 'text', valueOption: null,  matchMode: 'contains', displayType: 'text', visible: true, frozen: true, isID: false},
      {field: 'LastName', header: 'Last', fullHeader: 'Last Name', columnType: 'text', valueOption: null, matchMode: 'contains', displayType: 'text', visible: true, frozen: true, isID: false},
      {field: 'FirstName', header: 'First', fullHeader: 'First Name', columnType: 'text', valueOption: null, matchMode: 'contains', displayType: 'text', visible: true, frozen: true, isID: false},
      {field: 'MiddleName', header: 'Middle', fullHeader: 'Middle Name', columnType: 'text', valueOption: null, matchMode: 'contains', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'Prefix', header: 'Prefix', fullHeader: 'Prefix', columnType: 'picker', valueOption: 'Description', matchMode: 'equals', displayType: 'text', visible: false, frozen: false, isID: false},
      {field: 'Suffix', header: 'Suffix', fullHeader: 'Suffix', columnType: 'text', valueOption: null, matchMode: 'contains', visible: false, frozen: false, isID: false, displayType: 'text'},
      {field: 'Nickname', header: 'Nickname', fullHeader: 'Nickname', columnType: 'text', valueOption: null, matchMode: 'contains', visible: false, displayType: 'text', frozen: false, isID: false},
      {field: 'OrganizationName', header: STRINGREPLACELOCAL, fullHeader: STRINGREPLACELOCAL, columnType: 'picker', valueOption: 'MembershipAncestorOrganizationID', displayType: 'text', visible: true, frozen: false, isID: true},
      {field: 'CardIssueDate', header: 'Card Iss. Date', fullHeader: 'Card Issue Date', columnType: 'date', matchMode: 'equals', visible: true, displayType: 'date'},
      {field: 'Age', header: 'Age', fullHeader: 'Age', columnType: 'numeric', matchMode: 'equals', visible: true, frozen: false, isID: false, displayType: 'numeric'},
      {field: 'MemberNumber', header: 'Member #', fullHeader: 'Member #', columnType: 'text', valueOption: null, matchMode: 'contains', visible: true, frozen: false, isID: false, displayType: 'text'},
      {field: 'StatusAlias', header: 'Membership Status', fullHeader: 'Membership Status', valueOption: null, columnType: 'picker', visible: true, frozen: false, isID: false, displayType: 'text'},
      {field: 'TypeAlias', header: 'Membership ' + STRINGREPLACETYPE, fullHeader: 'Membership ' + STRINGREPLACETYPE, columnType: 'picker', valueOption: 'Description', visible: true, frozen: false, isID: false, displayType: 'text'},
      {field: 'DuesGroup', header: 'Dues Group', fullHeader: 'Dues Group', columnType: 'picker', valueOption: 'Description', visible: true, frozen: false, isID: false, displayType: 'text'}

    ];
    if (this._employmentTradeTab() === true) {
      this.rosterCols.push(
        {field: 'PrimaryTrade', header: 'Primary Trade', fullHeader: 'Primary Trade', columnType: 'picker', valueOption: 'Description', displayType: 'text', matchMode: 'equals', visible: true, frozen: false, isID: false},
        {field: 'PrimarySkill', header: 'Primary Skill', fullHeader: 'Primary Skill', columnType: 'picker', valueOption: 'Description', matchMode: 'equals', displayType: 'text', visible: true, frozen: false, isID: false}
      );
    }
    this.rosterCols.push(
      {field: 'ApplicationDate_D', header: 'Application Date', fullHeader: 'Application Date', columnType: 'date', valueOption: null, matchMode: 'equals', visible: true, frozen: false, isID: false, displayType: 'date'},
      {field: 'EffectiveDate_D', header: 'Effective Date', fullHeader: 'Effective Date', columnType: 'date', valueOption: null, matchMode: 'equals', visible: true, frozen: false, isID: false, displayType: 'date'},
      {field: 'Classification', header: STRINGREPLACECLASSIFICATION, fullHeader: STRINGREPLACECLASSIFICATION, columnType: 'picker', valueOption: 'Description', displayType: 'text', matchMode: 'equals', visible: false, frozen: false, isID: false},
      {field: 'BargainingUnitDescription', header: 'Bargaining Unit', fullHeader: 'Bargaining Unit', columnType: 'picker', valueOption: 'BargainingUnitID', displayType: 'text', matchMode: 'equals', visible: true, frozen: false, isID: true},
      {field: 'MemberStartDate_D', header: 'Initiation Date', fullHeader: 'Initiation Date', columnType: 'date', valueOption: null, matchMode: 'equals', displayType: 'date', visible: true, frozen: false, isID: false},
      {field: 'MemberRetirementDate_D', header: 'Retirement Date', fullHeader: 'Retirement Date', columnType: 'date', valueOption: null, matchMode: 'equals', displayType: 'date', visible: false, frozen: false, isID: false}
    );
    if (this._activityDrivenMembers() === true) {
      this.rosterCols.push(
        {field: 'LastActivity', header: 'Last Activity', fullHeader: 'Last Activity', columnType: 'picker', matchMode: 'contains', displayType: 'text', visible: true},
        {field: 'LastActivityDate_D', header: 'Last Activity Date', fullHeader: 'Last Activity Date', columnType: 'date', matchMode: 'gte', displayType: 'date', visible: true}
      );
    }
    this.rosterCols.push(
      {field: 'PrimaryOfficer', header: 'Primary Officer', fullHeader: 'Primary Officer', columnType: 'ctYESNO', displayType: 'text', valueOption: null, matchMode: 'equals', visible: true, frozen: false, isID: false},
      {field: 'Gender', header: 'Gender', fullHeader: 'Gender', columnType: 'picker', valueOption: 'Description', matchMode: 'equals', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'Race', header: 'Race/Ethnicity', fullHeader: 'Race/Ethnicity', columnType: 'picker', valueOption: 'Description', matchMode: 'equals', displayType: 'text', visible: true, frozen: false, isID: false},
      // {field: 'Race', header: 'Race', fullHeader: 'Race', columnType: 'picker', valueOption: 'Description', matchMode: 'equals', visible: false, frozen: false, isID: false},
      {field: 'Language', header: 'Language', fullHeader: 'Language', columnType: 'picker', valueOption: 'Description', matchMode: 'equals', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'PreferredLanguage', header: 'Preferred Language', fullHeader: 'Preferred Language', columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'Education', header: 'Education', fullHeader: 'Education', columnType: 'picker', valueOption: 'Description', matchMode: 'equals', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'Military', header: 'Military Branch', fullHeader: 'Military Branch', columnType: 'picker', valueOption: 'Description', matchMode: 'equals', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'MilitaryStatus', header: 'Military Status', fullHeader: 'Military Status', columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'EmployerName', header: 'Employer', fullHeader: 'Employer', columnType: 'picker', valueOption: 'Description', displayType: 'text', matchMode: 'equals', visible: true, frozen: false, isID: false},
      {field: 'EmploymentHireDate_D', header: 'Hire Date', fullHeader: 'Hire Date', columnType: 'date', displayType: 'text', valueOption: null, matchMode: 'equals', visible: false, frozen: false, isID: false},
      {field: 'EmploymentNumber', header: 'EIN', fullHeader: 'EIN', columnType: 'text', valueOption: null, displayType: 'text', matchMode: 'contains', visible: true, frozen: false, isID: false},
      {field: 'WorkSiteName', header: 'Worksite', fullHeader: 'Worksite', columnType: 'text', valueOption: null, displayType: 'text', matchMode: 'contains', visible: true, frozen: false, isID: false},
      {field: 'ContractDescription', header: 'Contract', fullHeader: 'Contract', columnType: 'text', valueOption: null, matchMode: 'contains', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'Shift', header: 'Shift', fullHeader: 'Shift', columnType: 'picker', valueOption: 'Description', matchMode: 'equals', displayType: 'text', visible: false, frozen: false, isID: false},
      {field: 'BillingStatusAlias', header: 'Billing Process', fullHeader: 'Billing Process', columnType: 'picker', valueOption: 'Description', displayType: 'text', matchMode: 'equals', visible: false, frozen: false, isID: false},
      {field: 'AccountBalance_N', header: 'Balance', fullHeader: 'Balance', columnType: 'numeric', valueOption: null, matchMode: 'gte', displayType: 'currency', visible: true, frozen: false, isID: false},
      {field: 'LastPayment_D', header: 'Dues Last Paid', fullHeader: 'Dues Last Paid', columnType: 'date', valueOption: null, matchMode: 'equals', displayType: 'date', visible: false, frozen: false, isID: false}
    );
    if (STATEMENTBILLING === true) {
      this.rosterCols.push(
        {field: 'PaidThroughDate_D', header: 'Paid Through Date', fullHeader: 'Paid Through Date', columnType: 'date', matchMode: 'gte', displayType: 'date', visible: true}
      );
    }
    this.rosterCols.push(
      {field: 'PaymentType', header: 'Dues Payment Election', fullHeader: 'Dues Payment Election', columnType: 'picker', valueOption: 'Description', matchMode: 'equals', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'AutoPay', header: 'Auto Pay', fullHeader: 'Auto Pay', columnType: 'ctYESNO', valueOption: null, matchMode: 'equals', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'HasAccount', header: 'User Account', fullHeader: 'User Account', columnType: 'ctYESNO', valueOption: null, matchMode: 'contains', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'PrimaryAddress1', header: 'Street Address', fullHeader: 'Street Address', columnType: 'text', valueOption: null, matchMode: 'contains', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'PrimaryAddress2', header: 'Ste, Bldg, Flr, etc.', fullHeader: 'Ste, Bldg, Flr, etc.', columnType: 'text', valueOption: null, matchMode: 'contains', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'PrimaryCity', header: 'City/Town', fullHeader: 'City/Town', columnType: 'text', valueOption: null, matchMode: 'contains', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'PrimaryStateProvince', header: 'State/Province', fullHeader: 'State/Province', columnType: 'text', valueOption: null, matchMode: 'contains', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'PrimaryZip', header: 'Postal Code', fullHeader: 'Postal Code', columnType: 'text', valueOption: null, matchMode: 'contains', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'PrimaryCounty', header: 'County', fullHeader: 'County', columnType: 'text', valueOption: null, matchMode: 'contains', displayType: 'text', visible: false, frozen: false, isID: false},
      {field: 'PrimaryCountry', header: 'Country', fullHeader: 'Country', columnType: 'text', valueOption: null, matchMode: 'contains', displayType: 'text', visible: false, frozen: false, isID: false},
      {field: 'PrimaryFailedCommunication', header: 'Undeliverable', fullHeader: 'Undeliverable', columnType: 'picker', valueOption: 'Description', displayType: 'text', matchMode: 'equals', visible: false, frozen: false, isID: false},
      {field: 'PrimaryPhoneNumber', header: 'Home Phone #', fullHeader: 'Home Phone #', columnType: 'text', valueOption: null, matchMode: 'contains', displayType: 'phone', visible: true, frozen: false, isID: false},
      {field: 'SecondaryPhoneNumber', header: 'Mobile Phone #', fullHeader: 'Mobile Phone #', columnType: 'text', valueOption: null, matchMode: 'contains', displayType: 'phone', visible: true, frozen: false, isID: false},
      {field: 'ThirdPhoneNumber', header: 'Other Phone #', fullHeader: 'Other Phone #', columnType: 'text', valueOption: null, matchMode: 'contains', displayType: 'phone', visible: false, frozen: false, isID: false},
      {field: 'SMSOptOut', header: 'SMS Opt Out', fullHeader: 'SMS Opt Out', columnType: 'ctYESNO', valueOption: null, matchMode: 'equals', displayType: 'text', visible: false, frozen: false, isID: false},
      {field: 'PrimaryEmailAddress', header: 'Primary E-mail', fullHeader: 'Primary E-mail', columnType: 'text', valueOption: null, matchMode: 'contains', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'PrimaryEmailOptOut', header: 'Primary E-mail Opt Out', fullHeader: 'Primary E-mail Opt Out', columnType: 'ctYESNO', valueOption: null, displayType: 'text', matchMode: 'equals', visible: false, frozen: false, isID: false},
      {field: 'PrimaryEmailFailedCommunication', header: 'Primary E-mail Undeliverable', fullHeader: 'Primary E-mail Undeliverable', columnType: 'ctYESNO', displayType: 'text', valueOption: null, matchMode: 'equals', visible: false, frozen: false, isID: false},
      {field: 'SecondaryEmailAddress', header: 'Secondary E-mail', fullHeader: 'Secondary E-mail', columnType: 'text', valueOption: null, matchMode: 'contains', displayType: 'text', visible: true, frozen: false, isID: false},
      {field: 'SecondaryEmailOptOut', header: 'Secondary E-mail Opt Out', fullHeader: 'Secondary E-mail Opt Out', columnType: 'ctYESNO', valueOption: null, displayType: 'text', matchMode: 'equals', visible: false, frozen: false, isID: false},
      {field: 'SecondaryEmailFailedCommunication', header: 'Secondary E-mail Undeliverable', fullHeader: 'Secondary E-mail Undeliverable', columnType: 'ctYESNO', displayType: 'text', valueOption: null, matchMode: 'equals', visible: false, frozen: false, isID: false}
    );

    this.prospectCols = [
      {field: 'LastName', header: 'Last Name', fullHeader: 'Last Name', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'FirstName', header: 'First Name', fullHeader: 'First Name', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'OrganizationName', header: STRINGREPLACELOCAL, fullHeader: STRINGREPLACELOCAL, columnType: 'picker', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'StatusAlias', header: 'Status', fullHeader: 'Status', columnType: 'picker', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'TypeAlias', header: 'Type', fullHeader: 'Type', columnType: 'picker', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'EmployerName', header: 'Employer', fullHeader: 'Employer', columnType: 'picker', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'HasAccount', header: 'User Account', fullHeader: 'Has Account', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'Education', header: 'Education', fullHeader: 'Education', columnType: 'picker', matchMode: 'contains', displayType: 'text', visible: false},
      {field: 'Language', header: 'Language', fullHeader: 'Language', columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: false},
      {field: 'MiddleName', header: 'Middle Name', fullHeader: 'Middle Name', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: false},
      {field: 'Military', header: 'Military Branch', fullHeader: 'Military Branch', columnType: 'picker', displayType: 'text', matchMode: 'contains', visible: false}
    ];

    this.caseCols = [
      {field: 'Description', header: 'Description', columnType: 'text', matchMode: 'contains', visible: true, displayType: 'text'},
      {field: 'WorkflowTypeDescription', header: 'Type', columnType: 'text', matchMode: 'contains', visible: true, displayType: 'text'},
      {field: 'UnionIdentifier', header: STRINGREPLACEUNIONIDENTIFIER, columnType: 'text', matchMode: 'contains', visible: true, displayType: 'text'},
      {field: 'FiledDate_D', header: STRINGREPLACEFILEDDATE, columnType: 'date', matchMode: 'contains', visible: true, displayType: 'date'},
      {field: 'NextDueDate_D', header: 'Next Due Date', columnType: 'date', matchMode: 'contains', visible: true, displayType: 'date'},
      {field: 'CurrentStep', header: 'Current Step', columnType: 'text', matchMode: 'contains', visible: true, displayType: 'text'},
      {field: 'Employee', header: 'Claimant(s)', columnType: 'text', matchMode: 'contains', visible: true, displayType: 'text'},
      {field: 'OrganizationName', header: STRINGREPLACELOCAL, columnType: 'text', matchMode: 'contains', visible: true, displayType: 'text'},
      {field: 'EmployerName', header: 'Employer', columnType: 'text', matchMode: 'contains', visible: true, displayType: 'text'},
      {field: 'EmployerIdentifier', header: STRINGREPLACEEMPLOYERIDENTIFIER, columnType: 'text', matchMode: 'contains', visible: true, displayType: 'text'},
      {field: 'ContractName', header: 'Contract', columnType: 'text', matchMode: 'contains', visible: true, displayType: 'text'},
      {field: 'OccupationDescription', header: STRINGREPLACEOCCUPATION, columnType: 'text', matchMode: 'contains', visible: true, displayType: 'text'},
      {field: 'ContactList', header: 'Contact(s)', columnType: 'text', matchMode: 'contains', visible: true, displayType: 'text'}
    ];
    if (CASEGRIDCUSTOMSTRING1) {
      this.caseCols.push({field: 'CustomString1', header: CASEGRIDCUSTOMSTRING1, columnType: 'text', matchMode: 'contains', visible: true, displayType: 'text'});
    }
    if (CASEGRIDCUSTOMSTRING2) {
      this.caseCols.push({field: 'CustomString2', header: CASEGRIDCUSTOMSTRING2, columnType: 'text', matchMode: 'contains', visible: true, displayType: 'text'});
    }
    if (CASEGRIDCUSTOMSTRING3) {
      this.caseCols.push({field: 'CustomString3', header: CASEGRIDCUSTOMSTRING3, columnType: 'text', matchMode: 'contains', visible: true, displayType: 'text'});
    }
    if (CASEGRIDCUSTOMSTRING4) {
      this.caseCols.push({field: 'CustomString4', header: CASEGRIDCUSTOMSTRING4, columnType: 'text', matchMode: 'contains', visible: true, displayType: 'text'});
    }
    if (CASEGRIDCUSTOMSTRING5) {
      this.caseCols.push({field: 'CustomString5', header: CASEGRIDCUSTOMSTRING5, columnType: 'text', matchMode: 'contains', visible: true, displayType: 'text'});
    }
    if (CASEGRIDCUSTOMSTRING6) {
      this.caseCols.push({field: 'CustomString6', header: CASEGRIDCUSTOMSTRING6, columnType: 'text', matchMode: 'contains', visible: true, displayType: 'text'});
    }

    this.projectReportsCols = [
      {field: 'ProjectName', header: 'Name of Project', fullHeader: 'Name of Project', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'OrganizationName', header: 'Organization', fullHeader: 'Organization', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'OrganizerList', header: 'Organizers', fullHeader: 'Organizers', columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: true},
      {field: 'ProjectPhaseDescription', header: 'Phase', fullHeader: 'Date of Visit', columnType: 'picker', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'ProjectTypeDescription', header: 'Type', fullHeader: 'Type', columnType: 'picker', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'ProjectValueDescription', header: 'Value', fullHeader: 'Estimated Value', columnType: 'picker', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'ProjectIndustryDescription', header: 'Industry', fullHeader: 'Industry', columnType: 'picker', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'ProjectFundingDescription', header: 'Funding', fullHeader: 'Funding', columnType: 'picker', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'County', header: 'County', fullHeader: 'County', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'StateProvinceAbbreviation', header: 'State', fullHeader: 'State', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'VisitDate_D', header: 'Date of Visit', fullHeader: 'Date of Visit', columnType: 'date', matchMode: 'contains', displayType: 'date', visible: true},
      {field: 'FollowupVisitDate_D', header: 'Date of Follow-Up Visit', fullHeader: 'Date of Follow-Up Visit', columnType: 'date', matchMode: 'contains', displayType: 'date', visible: true},
      {field: 'ActiveProject', header: 'Active', fullHeader: 'Active', columnType: 'boolean', matchMode: 'equals', displayType: 'boolean', visible: true}
    ];

    this.employersCols = [
      {field: 'Name', header: 'Employer Name', fullHeader: 'Employer Name', columnType: 'picker', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'ActiveRosterCount_N', header: 'Active Roster #', fullHeader: 'Active Roster #', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'Address1', header: 'Address', fullHeader: 'Address', columnType: 'text', matchMode: 'equals', displayType: 'text', visible: true},
      {field: 'City', header: 'City', fullHeader: 'City', columnType: 'text', matchMode: 'equals', displayType: 'text', visible: true},
      {field: 'StateProvince', header: 'State/Province', fullHeader: 'State/Province', columnType: 'text', matchMode: 'equals', displayType: 'text', visible: true},
      {field: 'Zip', header: 'Postal Code', fullHeader: 'Postal Code', columnType: 'text', matchMode: 'equals', displayType: 'text', visible: true},
      {field: 'ClassificationDescription', header: 'Classification', fullHeader: 'Classification', columnType: 'text', matchMode: 'equals', displayType: 'text', visible: true},
      {field: 'Number', header: 'Number', fullHeader: 'Number', columnType: 'text', matchMode: 'equals', displayType: 'text', visible: true},
      {field: 'Status', header: 'Status', fullHeader: 'Status', columnType: 'text', matchMode: 'equals', displayType: 'text', visible: true},
      {field: 'PhoneNumber', header: 'Phone', fullHeader: 'Phone', columnType: 'text', matchMode: 'equals', displayType: 'text', visible: true},
      {field: 'EmailAddress', header: 'Email', fullHeader: 'Email', columnType: 'text', matchMode: 'equals', displayType: 'text', visible: true}
    ];

    this.employmentCols = [
      {field: 'LastName', header: 'Last Name', fullHeader: 'Last Name', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'FirstName', header: 'First Name', fullHeader: 'First Name', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'EmployerName', header: 'Employer', fullHeader: 'Employer', columnType: 'picker', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'WorkSiteName', header: 'Work Site', fullHeader: 'Work Site', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'ContractDescription', header: 'Description', fullHeader: 'Contract Description', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'Occupation', header: 'Occupation', fullHeader: 'Occupation', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'Shift', header: 'Shift', fullHeader: 'Shift', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'Trade', header: 'Trade', fullHeader: 'Trade', columnType: 'picker', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'Craft', header: 'Craft', fullHeader: 'Craft', columnType: 'text', matchMode: 'equals', displayType: 'text', visible: true},
      {field: 'WorkLevel', header: 'Work Level', fullHeader: 'Work Level', columnType: 'text', matchMode: 'equals', displayType: 'text', visible: true},
      {field: 'Title', header: 'Title', fullHeader: 'Title', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'StartDate_D', header: 'Start Date', fullHeader: 'Start Date', columnType: 'date', matchMode: 'contains', displayType: 'date', visible: true},
      {field: 'EndDate_D', header: 'End Date', fullHeader: 'End Date', columnType: 'date', matchMode: 'equals', displayType: 'date', visible: true},
      {field: 'LastPaidDate_D', header: 'Last Paid Date', fullHeader: 'Last Paid Date', columnType: 'date', matchMode: 'equals', displayType: 'date', visible: true},
      {field: 'DispatchRate', header: 'Dispatch Rate', fullHeader: 'Dispatch Rate', columnType: 'text', matchMode: 'equals', displayType: 'currency', visible: true}
    ];

    this.organizationEmployerCols = [
      {field: 'OrganizationName', header: 'Employer', fullHeader: 'Employer', columnType: 'picker', matchMode: 'contains', displayType: 'text', visible: false},
      {field: 'TradeDescription', header: 'Trade', fullHeader: 'Trade', columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: true},
      {field: 'OrganizeProjectOrganizationTypeDescription', header: 'Type', fullHeader: 'Type', columnType: 'picker', matchMode: 'contains', displayType: 'text', visible: true}
    ];

    this.positionCols = [
      {field: 'LastName', header: 'Last Name', fullHeader: 'Last Name', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'FirstName', header: 'First Name', fullHeader: 'First Name', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'CurrentPosition', header: 'Current', fullHeader: 'Current', columnType: 'boolean', matchMode: 'equals', displayType: 'boolean', visible: false},
      {field: 'OrganizationName', header: STRINGREPLACELOCAL, fullHeader: STRINGREPLACELOCAL, columnType: 'text', matchMode: 'equals', displayType: 'text', visible: true},
      {field: 'OrganizationType', header: 'Organization Type', fullHeader: 'Type', columnType: 'picker', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'Position', header: 'Position', fullHeader: 'Position', columnType: 'picker', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'PositionTypeDescription', header: 'Position Type', fullHeader: 'Type', columnType: 'picker', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'PositionStartDate_D', header: 'Start Date', fullHeader: 'Start Date', columnType: 'date', matchMode: 'contains', displayType: 'date', visible: true},
      {field: 'PositionEndDate_D', header: 'End Date', fullHeader: 'End Date', columnType: 'date', matchMode: 'contains', displayType: 'date', visible: true}
    ];

    this.voterCols = [
      {field: 'LastName', header: 'Last Name', fullHeader: 'Last Name', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'FirstName', header: 'First Name', fullHeader: 'First Name', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'VoterRegistrationStatus', header: 'Voter Registration Status', fullHeader: 'Voter Registration Status', columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: false},
      {field: 'VoterStatus', header: 'Voter Status', fullHeader: 'Voter Status', columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: false},
      {field: 'PartyRegistration', header: 'Party Registration', fullHeader: 'Party Registration', columnType: 'picker', matchMode: 'equals', visible: false},
      {field: 'CountyName', header: 'County Name', fullHeader: 'County Name', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: false},
      {field: 'CongressionalDistrict', header: 'Congressional District', fullHeader: 'Congressional District', columnType: 'text', displayType: 'text', matchMode: 'contains', visible: false},
      {field: 'StateSenateDistrict', header: 'State Senate District', fullHeader: 'State Senate District', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: false},
      {field: 'StateHouseDistrict', header: 'State House District', fullHeader: 'State House District', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: false},
      {field: 'TownshipName', header: 'Township Name', fullHeader: 'Township Name', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: false},
      {field: 'WardName', header: 'Ward Name', fullHeader: 'Ward Name', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: false},
      {field: 'PrecinctCode', header: 'Precinct Code', fullHeader: 'Precinct Code', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: false},
      {field: 'PrecinctName', header: 'Precinct Name', fullHeader: 'Precinct Name', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: false},
      {field: 'NationalPrecinctCode', header: 'National Precinct Code', fullHeader: 'National Precinct Code', columnType: 'text', displayType: 'text', matchMode: 'contains', visible: false},
      {field: 'CountyCouncilDistrict', header: 'County Council District', fullHeader: 'County Council District', columnType: 'text', displayType: 'text', matchMode: 'contains', visible: false},
      {field: 'CityCouncilDistrict', header: 'City Council District', fullHeader: 'City Council District', columnType: 'text', displayType: 'text', matchMode: 'contains', visible: false},
      {field: 'MunicipalDistrict', header: 'Municipal District', fullHeader: 'Municipal District', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: false},
      {field: 'SchoolDistrict', header: 'School District', fullHeader: 'School District', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: false},
      {field: 'JudicialDistrict', header: 'Judicial District', fullHeader: 'Judicial District', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: false},
      {field: 'AbsenteeVoterIndicator', header: 'Absentee Voter Indicator', fullHeader: 'Absentee Voter Indicator', columnType: 'text', displayType: 'text', matchMode: 'contains', visible: false},
      {field: 'GeneralVotesSummary', header: 'General Votes Summary', fullHeader: 'General Votes Summary', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: false},
      {field: 'PrimaryVotesSummary', header: 'Primary Votes Summary', fullHeader: 'Primary Votes Summary', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: false},
      {field: 'VoterScore', header: 'Voter Score', fullHeader: 'Voter Score', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: false}
    ];

    this.classCols = [
      {field: 'LastName', header: 'Last Name', fullHeader: 'Last Name', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'FirstName', header: 'First Name', fullHeader: 'First Name', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'CourseDescription', header: 'Course', fullHeader: 'Course', columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: true},
      {field: 'ClassOrganizationName', header: 'Training Center', fullHeader: 'Training Center', columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: true},
      {field: 'ClassLocation', header: 'Location', fullHeader: 'Location', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'ClassStartDate', header: 'Start Date', fullHeader: 'Start Date', columnType: 'date', matchMode: 'contains', displayType: 'date', visible: true},
      // {field: 'ClassStartTime', header: 'Start Time', fullHeader: 'Start Time', columnType: 'time', matchMode: 'contains', displayType: 'time', visible: true},
      {field: 'ClassEndDate', header: 'End Date', fullHeader: 'End Date', columnType: 'date', matchMode: 'contains', displayType: 'date', visible: true},
      // {field: 'ClassEndTime', header: 'End Time', fullHeader: 'End Time', columnType: 'time', matchMode: 'contains', displayType: 'time', visible: true},
      {field: 'TrainingStatusDescription', header: 'Training Status', fullHeader: 'Training Status', columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: true},
      {field: 'ClassStatusDate', header: 'Status Date', fullHeader: 'Status Date', columnType: 'date', matchMode: 'contains', displayType: 'date', visible: true},
      {field: 'ResultDescription', header: 'Result', fullHeader: 'Result', columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: true},
      {field: 'CertificationNumber', header: 'Certification #', fullHeader: 'Certification #', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'CertificationName', header: 'Certification', fullHeader: 'Certification', columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: true}
    ];

    this.programCols = [
      {field: 'LastName', header: 'Last Name', fullHeader: 'Last Name', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'FirstName', header: 'First Name', fullHeader: 'First Name', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'ProgramDescription', header: 'Program Description', fullHeader: 'Program', columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: true},
      {field: 'ProgramTypeDescription', header: 'Program Type', fullHeader: 'Program Type', columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: true},
      {field: 'SubjectAreaDescription', header: 'Subject Area', fullHeader: 'Subject Area', columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: true},
      {field: 'ProgramOrganizationName', header: 'Organization', fullHeader: 'Organization', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'ProgramBaseWage', header: 'Base Wage', fullHeader: 'Base Wage', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'ProgramOnetCode', header: 'O*NET Code', fullHeader: 'O*NET Code', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'ProgramSicCode', header: 'SIC Code', fullHeader: 'SIC Code', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'ProgramNaicsCode', header: 'NAICS Code', fullHeader: 'NAICS Code', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'ProgramRapidsCode', header: 'RAPIDS Code', fullHeader: 'RAPIDS Code', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'ProgramRapidsProgramID', header: 'RAPIDS Program Code', fullHeader: 'RAPIDS Program Code', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'TrainingStatusDescription', header: 'Training Status', fullHeader: 'Training Status', columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: true},
      {field: 'ProgramStudentOrganizationName', header: 'Training Center', fullHeader: 'Training Center', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'ProgramStudentStartDate', header: 'Program Start Date', fullHeader: 'Program Start Date', columnType: 'date', matchMode: 'contains', displayType: 'date', visible: true},
      {field: 'ProgramStudentEndDate', header: 'Program End Date', fullHeader: 'Program End Date', columnType: 'date', matchMode: 'contains', displayType: 'date', visible: true},
      // {field: 'ProgramStudentIdenitifier', header: 'Location', fullHeader: 'Location', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'StudentGroupID', header: 'Student Group', fullHeader: 'Student Group', columnType: 'picker', matchMode: 'contains', displayType: 'text', visible: true, isID: true},
    ];

    this.organizationCol = [
      {field: 'UTStatusDescription', header: 'Launch Status', columnType: 'text', matchMode: 'contains', visible: true, displayType: 'text'},
      {field: 'OrganizationType', header: 'Type', columnType: 'text', matchMode: 'contains', visible: true, displayType: 'text'},
      {field: 'Name', header: 'Name', columnType: 'text', matchMode: 'contains', visible: true, displayType: 'text'},
      {field: 'Number', header: 'Number', columnType: 'text', matchMode: 'contains', visible: true, displayType: 'text'},
      {field: 'City', header: 'City/Town', columnType: 'text', matchMode: 'contains', visible: true, displayType: 'text'},
      {field: 'StateProvince', header: 'State/Province', columnType: 'text', matchMode: 'contains', visible: true, displayType: 'text'},
      {field: 'Status', header: 'Status', columnType: 'text', matchMode: 'contains', visible: true, displayType: 'text'},
      {field: 'ActiveRosterCount_N', header: '# Active', columnType: 'numeric', matchMode: 'gte', visible: true, displayType: 'numeric'},
      {field: 'RegisteredCount_N', header: '# Registered', columnType: 'numeric', matchMode: 'gte', visible: true, displayType: 'numeric'},
      {field: 'BondAmount_N', header: 'Bond Amount', columnType: 'numeric', matchMode: 'gte', visible: true, displayType: 'currency'}
    ];

    this.communicationCols = [
      {field: 'CommunicationID', header: 'Communication', fullHeader: 'Description', columnType: 'select', matchMode: 'contains', displayType: 'text', visible: true},
      {field: 'SendpublishStatusDescription', header: 'Status', fullHeader: 'Status', columnType: 'picker', matchMode: 'contains', displayType: 'text', visible: true}
    ];

    this.certificationCols = [
      {field: 'CertificationDescription', header: 'Certification', fullHeader: 'Description', columnType: 'picker', matchMode: 'contains', displayType: 'text', visible: true}
    ];

    this.sendPublishStatusValues = [
      {Description: 'Failed', ID: 5},
      {Description: 'Delivered', ID: 6},
      {Description: 'Opened', ID: 7},
      {Description: 'Engaged', ID: 8},
    ];

    this.booleanValues = [
      {Description: 'Yes', ID: true},
      {Description: 'No', ID: false},
    ];

    this.yesNoValues = [
      {Description: 'Yes', ID: 'Yes'},
      {Description: 'No', ID: 'No'},
      {Description: 'Is Empty', ID: 'isEmpty'}
    ];

    this.emergencyContactsCols = [
      {field: 'FirstName', header: 'First Name', fullHeader: 'First Name', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true, snapshotDisplayType: 'text'},
      {field: 'LastName', header: 'Last Name', fullHeader: 'Last Name', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true, snapshotDisplayType: 'text'},
      {field: 'EmailAddress', header: 'Email', fullHeader: 'Email', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true, snapshotDisplayType: 'text'},
      {field: 'PhoneNumber', header: 'Phone', fullHeader: 'Phone', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true, snapshotDisplayType: 'text'},
      {field: 'Address1', header: 'Address', fullHeader: 'Address', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true, snapshotDisplayType: 'text'},
      {field: 'City', header: 'City', fullHeader: 'City', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true, snapshotDisplayType: 'text'}
    ];

    this.studentRosterContactCols = [
      {field: 'Prefix', header: 'Prefix', fullHeader: 'Prefix', columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: false, frozen: false, isID: false, snapshotDisplayType: 'text'},
      {field: 'LastName', header: 'Last Name', fullHeader: 'Last Name', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true, frozen: true, isID: false, snapshotDisplayType: 'text'},
      {field: 'FirstName', header: 'First Name', fullHeader: 'First Name', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true, frozen: true, isID: false, snapshotDisplayType: 'text'},
      {field: 'MiddleName', header: 'Middle Name', fullHeader: 'Middle Name', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true, frozen: true, isID: false, snapshotDisplayType: 'text'},
      {field: 'PrimaryPhoneNumber', header: 'Home Phone #', fullHeader: 'Home Phone #', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'text'},
      {field: 'SecondaryPhoneNumber', header: 'Mobile Phone #', fullHeader: 'Mobile Phone #', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'text'},
      {field: 'SMSOptOut', header: 'SMS Opt Out', fullHeader: 'SMS Opt Out', columnType: 'ctYESNO', matchMode: 'equals', displayType: 'text', visible: false, frozen: true, isID: false, snapshotDisplayType: 'text'},
      {field: 'PrimaryEmailAddress', header: 'Primary E-mail', fullHeader: 'Primary E-mail', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'text'},
      {field: 'PrimaryEmailOptOut', header: 'Primary E-mail Opt Out', fullHeader: 'Primary E-mail Opt Out', columnType: 'YESNO', matchMode: 'equals', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'yesNo'},
      {field: 'SecondaryEmailAddress', header: 'Business E-mail', fullHeader: 'Business E-mail', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'text'},
      {field: 'SecondaryEmailOptOut', header: 'Secondary E-mail Opt Out', fullHeader: 'Secondary E-mail Opt Out', columnType: 'YESNO', matchMode: 'equals', displayType: 'text', visible: true, frozen: false, snapshotDisplayType: 'yesNo'},
      {field: 'PrimaryAddress1', header: 'Street Address', fullHeader: 'Street Address', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: false, frozen: true, isID: false, snapshotDisplayType: 'text'},
      {field: 'PrimaryAddress2', header: 'Ste, Bldg, Flr, etc.', fullHeader: 'Ste, Bldg, Flr, etc.', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'text'},
      {field: 'PrimaryCity', header: 'City/Town', fullHeader: 'City/Town', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'text'},
      {field: 'PrimaryStateProvince', header: 'State/Province', fullHeader: 'State/Province', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'text'},
      {field: 'PrimaryZip', header: 'Postal Code', fullHeader: 'Postal Code', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'text'},
      {field: 'PrimaryCounty', header: 'County', fullHeader: 'County', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'text'},
      {field: 'ThirdPhoneNumber', header: 'Other Phone #', fullHeader: 'Other Phone #', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'text'},
      {field: 'PublicPhoneNumber', header: 'Public Phone #', fullHeader: 'Public Phone #', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'text'},
      {field: 'PrimaryEmailFailedCommunication', header: 'Primary E-mail Undeliverable', fullHeader: 'Primary E-mail Undeliverable', columnType: 'ctYESNO', matchMode: 'equals', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'yesNo'},
      {field: 'SecondaryEmailFailedCommunication', header: 'Secondary E-mail Undeliverable', fullHeader: 'Secondary E-mail Undeliverable', columnType: 'ctYESNO', matchMode: 'equals', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'yesNo'},
      {field: 'PublicEmailAddress', header: 'Public E-mail', fullHeader: 'Public E-mail', columnType: 'text', matchMode: 'contains', visible: true, displayType: 'text', frozen: false, isID: false, snapshotDisplayType: 'text'},
      {field: 'PublicEmailFailedCommunication', header: 'Public E-mail Undeliverable', fullHeader: 'Public E-mail Undeliverable', columnType: 'ctYESNO', displayType: 'text', matchMode: 'equals', visible: true, frozen: false, isID: false, snapshotDisplayType: 'yesNo'},
      {field: 'HasAccount', header: 'User Account', fullHeader: 'User Account', columnType: 'ctYESNO', matchMode: 'contains', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'text'},
      {field: 'Age', header: 'Age', fullHeader: 'Age', columnType: 'numeric', displayType: 'numeric', matchMode: 'equals', visible: true, frozen: false, isID: false, snapshotDisplayType: 'numeric'},

    ];

    this.studentRosterDemographicsCols = [
      {field: 'Gender', header: 'Gender', fullHeader: 'Gender', columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'text'},
      {field: 'Race', header: 'Race/Ethnicity', fullHeader: 'Race/Ethnicity', columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'text'},
      {field: 'Language', header: 'Language', fullHeader: 'Language', columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'text'},
      {field: 'Education', header: 'Education', fullHeader: 'Education', columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'text'},
      {field: 'SchoolAttended', header: 'School', fullHeader: 'School', columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'text'},
      {field: 'Military', header: 'Military Branch', fullHeader: 'Military Branch', columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'text'},
      {field: 'MilitaryStatus', header: 'Military Status', fullHeader: 'Military Status', columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'text'},
    ];

    this.studentRosterMembershipCol = [
      {field: 'OrganizationName', header: STRINGREPLACELOCAL, fullHeader: STRINGREPLACELOCAL, columnType: 'picker', displayType: 'text', visible: true, frozen: false, isID: true, snapshotDisplayType: 'text'},
      {field: 'TypeAlias', header: 'Student Type', fullHeader: 'Training Type', columnType: 'picker', displayType: 'text', visible: true, frozen: true, isID: false, snapshotDisplayType: 'text'},
      {field: 'Classification', header: STRINGREPLACECLASSIFICATION, fullHeader: STRINGREPLACECLASSIFICATION, columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'text'},
      {field: 'MemberStatusAlias', header: 'Membership Status', fullHeader: 'Membership Status', columnType: 'picker', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'text'},
      {field: 'StatusAlias', header: 'Student Status', fullHeader: 'Student Status', columnType: 'picker', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'text'},
      {field: 'StudentNumber', header: 'Student #', fullHeader: 'Student #', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'text'},
      {field: 'MemberNumber', header: 'Member #', fullHeader: 'Member #', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'text'},
      {field: 'CardIssueDate', header: 'Card Iss. Date', fullHeader: 'Card Issue Date', columnType: 'date', displayType: 'text', matchMode: 'equals', visible: true, snapshotDisplayType: 'date'},
      {field: 'ProgramStatus', header: 'Program Status', fullHeader: 'Program Status', columnType: 'picker', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'text'},
      {field: 'MemberTypeAlias', header: 'Membership ' + STRINGREPLACETYPE, fullHeader: 'Membership ' + STRINGREPLACETYPE, columnType: 'picker', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'text'},
      {field: 'ProgramTrainingCenterName', header: 'Training Center', fullHeader: 'Program Training Center', columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'text'},
      {field: 'ProgramDescription', header: 'Program Description', fullHeader: 'Program Description', columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'text'},
      {field: 'BargainingUnitDescription', header: 'Bargaining Unit', fullHeader: 'Bargaining Unit', columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: true, frozen: false, isID: true, snapshotDisplayType: 'text'},
      {field: 'Instructor', header: 'Instructor', fullHeader: 'Instructor', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'text'},
      {field: 'StudentGroupDescription', header: 'Student Group', fullHeader: 'Student Group', columnType: 'picker', matchMode: 'contains', displayType: 'text', visible: true, isID: false},
      {field: 'StudentGroupAlias', header: 'Student Group Alias', fullHeader: 'Student Group Alias', columnType: 'text', matchMode: 'contains', displayType: 'text', visible: true, snapshotDisplayType: 'text'},
    ];

    this.studentRosterProgramsCol = [
      {field: 'ProgramDescription', header: 'Program', fullHeader: 'Program', columnType: 'picker', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'line-item-header'},
      {field: 'OrganizationDescription', header: STRINGREPLACELOCAL, fullHeader: STRINGREPLACELOCAL, columnType: 'picker', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'text'},
      {field: 'Active', header: 'Active', fullHeader: 'Active', columnType: 'text', displayType: 'text', visible: true, frozen: false, isID: true, snapshotDisplayType: 'yesNo'},
      {field: 'EmployerReportedHours', header: 'Reported Hours', fullHeader: 'Reported Hours', columnType: 'text', displayType: 'text', visible: true, frozen: true, isID: false, snapshotDisplayType: 'text'},
      {field: 'StartDate_D', header: 'Start Date', fullHeader: 'Start Date', columnType: 'text', matchMode: 'equals', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'date'},
      {field: 'EndDate_D', header: 'End Date', fullHeader: 'End Date', columnType: 'text', matchMode: 'equals', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'date'},
      {field: 'FacetType1RecordedHours_N', header: 'Facet 1 Recorded Hours', fullHeader: 'Facet 1 Recorded Hours', columnType: 'picker', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'text'},
      {field: 'FacetType1RequiredHours_N', header: 'Facet 1 Required Hours', fullHeader: 'Facet 1 Required Hours', columnType: 'picker', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'text'},
      {field: 'FacetType1Percentage', header: 'Facet 1 %', fullHeader: 'Facet 1 %', columnType: 'picker', displayType: 'numeric', visible: true, frozen: false, isID: false, snapshotDisplayType: 'numeric'},
      {field: 'FacetType1Completed', header: 'Facet 1 Completed', fullHeader: 'Facet 1 Completed', columnType: 'picker', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'yesNo'},
      {field: 'FacetType2RecordedHours_N', header: 'Facet 2 Recorded Hours', fullHeader: 'Facet 2 Recorded Hours', columnType: 'picker', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'text'},
      {field: 'FacetType2RequiredHours_N', header: 'Facet 2 Required Hours', fullHeader: 'Facet 2 Required Hours', columnType: 'picker', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'text'},
      {field: 'FacetType2Percentage', header: 'Facet 2 %', fullHeader: 'Facet 2 %', columnType: 'picker', displayType: 'numeric', visible: true, frozen: false, isID: false, snapshotDisplayType: 'numeric'},
      {field: 'FacetType2Completed', header: 'Facet 2 Completed', fullHeader: 'Facet 2 Completed', columnType: 'picker', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'yesNo'},
      {field: 'RateAmount', header: 'Rate Amount', fullHeader: 'Rate Amount', columnType: 'picker', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'text'},
      {field: 'RateLevel', header: 'Rate Level', fullHeader: 'Rate Level', columnType: 'picker', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'text'},
      {field: 'RateLevelDescription', header: 'Rate Level Desc.', fullHeader: 'Rate Level Desc.', columnType: 'picker', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'text'},
      {field: 'StudentGroupDescription', header: 'Student Group', fullHeader: 'Student Group', columnType: 'picker', matchMode: 'contains', displayType: 'text', visible: true, isID: false},
      {field: 'TrainingStatusDescription', header: 'Training Status', fullHeader: 'Training Status', columnType: 'picker', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'text'},
    ];

    this.wageRatesCols = [
      {field: 'ProgramWageRateDescription', header: 'Description', fullHeader: 'Description', columnType: 'picker', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'text'},
      {field: 'CalculatedWageRate', header: 'Rate', fullHeader: 'Rate', columnType: 'text', displayType: 'currency', visible: true, frozen: false, isID: true, snapshotDisplayType: 'currency'},
      {field: 'StartDate_D', header: 'Start Date', fullHeader: 'Start Date', columnType: 'text', matchMode: 'equals', displayType: 'date', visible: true, frozen: false, isID: false, snapshotDisplayType: 'date'},
      {field: 'EndDate_D', header: 'End Date', fullHeader: 'End Date', columnType: 'text', matchMode: 'equals', displayType: 'date', visible: true, frozen: false, isID: false, snapshotDisplayType: 'date'},
    ];

    this.secureDataCols = [
      {field: 'Age', header: 'Age', fullHeader: 'Age', columnType: 'picker', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'text'},
      {field: 'DOBSecure_D', header: 'DOB', fullHeader: 'DOB', columnType: 'text', displayType: 'date', visible: true, frozen: false, isID: true, snapshotDisplayType: 'date'},
      {field: 'SSN', header: 'SSN', fullHeader: 'SSN', columnType: 'text', matchMode: 'equals', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'text'},
      {field: 'SIN', header: 'SIN', fullHeader: 'SIN', columnType: 'text', matchMode: 'equals', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'text'},
    ];

    this.studentRosterStudentCol = [
      {field: 'TypeAlias', header: 'Student Type', fullHeader: 'Student Type', columnType: 'picker', displayType: 'text', visible: true, frozen: true, isID: false, snapshotDisplayType: 'text'},
      {field: 'StatusAlias', header: 'Student Status', fullHeader: 'Student Status', columnType: 'picker', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'text'},
      {field: 'StudentNumber', header: 'Student #', fullHeader: 'Student #', columnType: 'picker', matchMode: 'equals', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'text'},
      {field: 'EffectiveDate', header: 'Effective Date', fullHeader: 'Effective Date', columnType: 'picker', displayType: 'date', visible: true, frozen: false, isID: false, snapshotDisplayType: 'date'},
    ];

    this.studentClassesCol = [
      {field: 'CourseDescription', header: 'Course', fullHeader: 'Course', columnType: 'picker', displayType: 'text', visible: true, frozen: true, isID: false, snapshotDisplayType: 'text'},
      {field: 'TrainingStatusDescription', header: 'Training Status', fullHeader: 'Status', columnType: 'picker', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'text'},
      {field: 'StartDate_D', header: 'Start Date', fullHeader: 'Start Date', columnType: 'text', matchMode: 'equals', displayType: 'date', visible: true, frozen: false, isID: false, snapshotDisplayType: 'date'},
      {field: 'EndDate_D', header: 'End Date', fullHeader: 'End Date', columnType: 'text', matchMode: 'equals', displayType: 'date', visible: true, frozen: false, isID: false, snapshotDisplayType: 'date'},
      {field: 'Grade', header: 'Grade', fullHeader: 'Grade', columnType: 'text', displayType: 'numeric', visible: true, frozen: false, isID: false, snapshotDisplayType: 'numeric'},
      {field: 'ResultDescription', header: 'Result', fullHeader: 'Result', columnType: 'picker', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'text'},
      {field: 'IsModule', header: 'Module', fullHeader: 'Module', columnType: 'picker', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'yesNo'},

    ];

    this.studentCertificationsCol = [
      {field: 'Title', header: 'Title', fullHeader: 'Title', columnType: 'picker', displayType: 'text', visible: true, frozen: true, isID: false, snapshotDisplayType: 'text'},
      {field: 'CompletionDate_D', header: 'Issue Date', fullHeader: 'Issue Date', columnType: 'text', matchMode: 'equals', displayType: 'date', visible: true, frozen: false, isID: false, snapshotDisplayType: 'date'},
      {field: 'ExpirationDate_D', header: 'End Date', fullHeader: 'End Date', columnType: 'text', matchMode: 'equals', displayType: 'date', visible: true, frozen: false, isID: false, snapshotDisplayType: 'date'},
      {field: 'Body', header: 'Description', fullHeader: 'Description', columnType: 'picker', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'text'},
    ];

    this.studentEmploymentCol = [
      {field: 'EmployerName', header: 'Employer', fullHeader: 'Employer', columnType: 'picker', displayType: 'text', visible: true, frozen: true, isID: false, snapshotDisplayType: 'line-item-header'},
      {field: 'PrimaryEmployment', header: 'Primary', fullHeader: 'Primary', columnType: 'text', matchMode: 'equals', displayType: 'date', visible: true, frozen: false, isID: false, snapshotDisplayType: 'text'},
      {field: 'TradeDescription', header: 'Trade', fullHeader: 'Trade', columnType: 'text', matchMode: 'equals', displayType: 'date', visible: true, frozen: false, isID: false, snapshotDisplayType: 'text'},
      {field: 'WorkSiteName', header: 'Worksite', fullHeader: 'Worksite', columnType: 'text', matchMode: 'equals', displayType: 'date', visible: true, frozen: false, isID: false, snapshotDisplayType: 'text'},
      {field: 'ContractDescription', header: 'Contract', fullHeader: 'Contract', columnType: 'picker', displayType: 'text', visible: true, frozen: false, isID: false, snapshotDisplayType: 'text'},
      {field: 'WageLevelDescription', header: 'Wage Level', fullHeader: 'Wage Level', columnType: 'picker', displayType: 'text', visible: true, frozen: true, isID: false, snapshotDisplayType: 'text'},
      {field: 'EmploymentNumber', header: 'EIN', fullHeader: 'EIN', columnType: 'text', matchMode: 'equals', displayType: 'date', visible: true, frozen: false, isID: false, snapshotDisplayType: 'text'},
      {field: 'ShiftDescription', header: 'Shift', fullHeader: 'Shift', columnType: 'text', matchMode: 'equals', displayType: 'date', visible: true, frozen: false, isID: false, snapshotDisplayType: 'text'},
      {field: 'StartDate_D', header: 'Start Date', fullHeader: 'Start Date', columnType: 'text', matchMode: 'equals', displayType: 'date', visible: true, frozen: false, isID: false, snapshotDisplayType: 'date'},
      {field: 'EndDate_D', header: 'End Date', fullHeader: 'End Date', columnType: 'text', matchMode: 'equals', displayType: 'date', visible: true, frozen: false, isID: false, snapshotDisplayType: 'date'},
    ];

    this.studentTradesCol = [
      {field: 'TradeDescription', header: 'Trade', fullHeader: 'Trade', columnType: 'picker', displayType: 'text', visible: true, frozen: true, isID: false, snapshotDisplayType: 'text'},
      {field: 'Primary', header: 'Primary', fullHeader: 'Primary', columnType: 'text', matchMode: 'equals', displayType: 'date', visible: true, frozen: false, isID: false, snapshotDisplayType: 'yesNo'},
    ];

    this.studentSkillsCol = [
      {field: 'SkillDescription', header: 'Skill', fullHeader: 'Skill', columnType: 'picker', displayType: 'text', visible: true, frozen: true, isID: false, snapshotDisplayType: 'text'},
      {field: 'TradeDescription', header: 'Trade', fullHeader: 'Trade', columnType: 'text', matchMode: 'equals', displayType: 'date', visible: true, frozen: false, isID: false, snapshotDisplayType: 'text'},
      {field: 'Primary', header: 'Primary', fullHeader: 'Primary', columnType: 'text', matchMode: 'equals', displayType: 'date', visible: true, frozen: false, isID: false, snapshotDisplayType: 'yesNo'},
    ];

    this.studentCustomFieldsCols = [
      {field: 'Attribute', header: 'Field', fullHeader: 'Field', columnType: 'picker', displayType: 'text', visible: true, frozen: true, isID: false, snapshotDisplayType: 'text'},
    ];

    this.studentNotesCols = [
      {field: 'CreatedBy', header: 'Created By', fullHeader: 'Created By', columnType: 'picker', displayType: 'text', visible: true, frozen: true, isID: false, snapshotDisplayType: 'text'},
      {field: 'CreatedDate_D', header: 'Created Date', fullHeader: 'Created Date', columnType: 'text', matchMode: 'equals', displayType: 'date', visible: true, frozen: false, isID: false, snapshotDisplayType: 'date'},
      {field: 'Body', header: 'Note', fullHeader: 'Note', columnType: 'text', matchMode: 'equals', displayType: 'date', visible: true, frozen: false, isID: false, snapshotDisplayType: 'paragraph'},
    ];
  }
}
