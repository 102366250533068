import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {SharedImports} from '@shared/shared-imports';
import {takeUntil} from 'rxjs/operators';
import {ConfirmationService, MessageService} from 'primeng/api';
import {UpdateUserSurveyResponseModel, UserSurveyLandingModel, UserSurveyModel} from '@app/models/user-surveys.model';
import {UserSurveysService} from '@services/user-surveys.service';
import {ContextModel} from '@app/models/context.model';
import {EnvironmentsService} from '@services/environments.service';
import {ContextService} from '@services/context.service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss'],
   standalone: true,

  imports: [SharedImports]
})
export class SurveyComponent implements OnInit, OnDestroy {
  landing: UserSurveyLandingModel;
  survey: UserSurveyModel;
  loading: boolean;
  isDirty: boolean;
  isSaving: boolean = false;
  reqCheck: boolean = false;
  fieldType: string;
  tenant: string;
  logo: string;
  pid: string;
  spid: string;
  tid: string;
  responses: any[] = [];
  showErrorWarning: boolean;
  @Input() isEdit: boolean;
  @Input() SendPublishID: number;
  @Input() canTabWrite: boolean;
  @Input() attributeId: number;
  @Input() attributeValue: string;
  @Output() closeAddEdit = new EventEmitter<boolean>();

  private ngUnsubscribe = new Subject();

  constructor(private router: Router, private route: ActivatedRoute,
              private confirmationService: ConfirmationService,
              private messageService: MessageService,
              private userSurveysService: UserSurveysService,
              private environmentsService: EnvironmentsService,
              private contextService: ContextService, private titleService: Title
  ) {
  }

  ngOnInit(): void {
    this.getBaseUrl().then(() => {
      this.getSurvey();
    });
  }

  getSurvey(): void {
    this.route.queryParams.subscribe({
      next: (params) => {
        if (params && params.pid) {
          this.pid = params.pid;
        } else {
          this.pid = null;
        }
        if (params && params.spid) {
          this.spid = params.spid;
        } else {
          this.spid = null;
        }
        if (params && params.tid) {
          this.tid = params.tid;
        } else {
          this.tid = null;
        }
        this.userSurveysService
          .getUserSurveyLanding(this.spid, this.tid, this.pid)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              this.landing = res;
              let url: string;
              switch (true) {
                case this.landing.Submitted === true:
                  this.messageService.add({severity: 'error', summary: 'Survey Submitted', detail: 'This survey has already been submitted. You may login and go to your surveys to review your responses.', life: 3500});
                  setTimeout(() => {
                    localStorage.setItem('redirect', `/surveys/list?spid=${this.spid}&tid=${this.tid}`);
                    this.router.navigateByUrl('login');
                  }, 4000);
                  break;
                case this.landing.Expired === true:
                  this.messageService.add({severity: 'error', summary: 'Survey Expired', detail: 'This survey has expired. You may login and go to your surveys to review your responses.', life: 3500});
                  setTimeout(() => {
                    localStorage.setItem('redirect', `/surveys/list?spid=${this.spid}&tid=${this.tid}`);
                    this.router.navigateByUrl('login');
                  }, 4000);
                  break;
                case this.landing.Secure === true && this.landing.HasAccount === true:
                  this.messageService.add({severity: 'error', summary: 'Login Required', detail: 'Please login to complete this survey.', life: 3500});
                  setTimeout(() => {
                    localStorage.setItem('redirect', `/surveys/list?spid=${this.spid}&tid=${this.tid}`);
                    this.router.navigateByUrl('login');
                  }, 4000);
                  break;
                case this.landing.Secure === true && this.landing.HasAccount === false:
                  this.messageService.add({severity: 'error', summary: 'Registration Required', detail: 'Please first register, then you may login to complete this survey.', life: 3500});
                  setTimeout(() => {
                    url = `/public/verify-invitation?username=${this.landing.EncodedUserName}&token=${this.landing.Token}&personid=${this.landing.EncodedPersonID}&spid=${this.spid}&tid=${this.tid}`;
                    this.router.navigateByUrl(url);
                  }, 4000);
                  break;
                default:
                  this.loadSurvey();
                  break;
              }
            }, error: () => {
              this.showErrorWarning = true;
            }
          });
      }
    });
  }

  loadSurvey(): void {
    this.loading = true;
    this.userSurveysService
      .getUserSurvey(+this.spid, +this.tid)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (res: UserSurveyModel) => {
          let cnt: number = 0;
          const s: UserSurveyModel = res;
          s.Questions.forEach(x => {
            this.responses[cnt] = x.Response;
            if (x.Options && x.Options.length > 0) {
              x.Options.unshift({
                ID: null,
                CustomFieldID: null,
                Description: '--',
                Rank: 0
              });
            }
            cnt++;
          });
          this.survey = s;
          this.loading = false;
        }
      });
  }

  getBaseUrl() {
    return new Promise((resolve, reject) => {

      this.environmentsService.getEnvironment(window.location.hostname).then((rtnTenant) => {
        if (rtnTenant) {
          this.setUrl(rtnTenant);
        }
      });


      // if(environment.environmentName === 'demo') {
      //   this.environmentsService.getEnvironment('demo').then((rtnTenant) => {
      //     if (rtnTenant) {
      //       this.setUrl(rtnTenant);
      //     }
      //   });
      // } else if(environment.environmentName === 'dev') {
      //   this.environmentsService.getEnvironment('dev').then((rtnTenant) => {
      //     if (rtnTenant) {
      //       this.setUrl(rtnTenant);
      //     }
      //   });
      // } else if(environment.local && environment.environmentName !== 'dev' && environment.environmentName !== 'demo') {
      //   this.environmentsService.getEnvironment(`${this.tenant.toLowerCase()}-${environment.environmentName}`).then((rtnTenant) => {
      //     if(rtnTenant) {
      //       this.setUrl(rtnTenant);
      //     }
      //   });
      // } else if(!environment.local) {
      //   this.environmentsService.getEnvironment(window.location.hostname).then((rtnTenant) => {
      //     if (rtnTenant) {
      //       this.setUrl(rtnTenant);
      //     }
      //   });
      // }
      resolve(null);
    });
  }

  setUrl(rtnTenant) {
    const context = {} as ContextModel;
    context.apiBaseUrlV1 = rtnTenant.apiBaseUrlV1;
    context.tenantName = rtnTenant.tenantEnum;
    context.tenantLogo = rtnTenant.tenantLogo;
    context.accessToken = null;
    context.multiTenant = rtnTenant.multiTenant;

    this.titleService.setTitle(rtnTenant.pageTitle);
    sessionStorage.setItem('pageTitle', rtnTenant.pageTitle);
    this.contextService.contextObject = context;
    this.logo = this.contextService.contextObject.tenantLogo;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  saveResponse(questionID: number, responseIndex: number): void {
    if (this.spid && questionID && this.responses[responseIndex]) {
      const data: UpdateUserSurveyResponseModel = {
        SendPublishID: +this.spid,
        QuestionID: questionID,
        Response: this.responses[responseIndex]
      };
      this.userSurveysService.updateUserSurveyResponseAnon(this.tid, data)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.messageService.add({severity: 'success', summary: 'Success', detail: 'Your response has been saved'});
          }, error: () => {
            this.messageService.add({severity: 'error', summary: 'Error', detail: 'Response not saved'});
          }
        });
    }
  }

  checkReq(): boolean {
    let rtn: boolean;
    let cnt: number = 0;
    this.survey.Questions.forEach(x => {
      if (x.RequiredCount > 0 && (this.responses[cnt] === null || this.responses[cnt] === undefined || this.responses[cnt] === '')) {
        rtn = false;
      }
      cnt++;
    });
    return rtn !== false;
  }

  submitSurvey(): void {
    this.reqCheck = true;
    this.isSaving = true;
    if (this.checkReq()) {
      this.userSurveysService.submitUserSurveyResponseAnon(+this.spid, +this.tid)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.messageService.add({severity: 'success', summary: 'Success', detail: 'Thank you for submitting your responses to this survey. You rock! Have a great day!'});
            this.getSurvey();
          }, error: () => {
            this.messageService.add({severity: 'error', summary: 'Error', detail: 'Survey not submitted'});
          }
        });
    } else {
      this.isSaving = false;
    }
  }
}
