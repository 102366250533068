import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {LookupsService} from '@services/lookups.service';
import {OrganizationsByObjectAccessLookupModel} from '@app/models/lookups.model';
import {ContextService} from '@services/context.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {TenantConfigDataService} from '@services/tenant-config-data.service';
import {SharedImports} from '@shared/shared-imports';

@Component({
  selector: 'app-data-perspective-dropdown',
  templateUrl: './data-perspective-dropdown.component.html',
  styleUrls: ['./data-perspective-dropdown.component.scss'],
   standalone: true,

  imports: [SharedImports]
})
export class DataPerspectiveDropdownComponent implements OnInit, OnDestroy {
  context = this.contextService.contextObject;

  contexts: any[];
  selectedContext: string;
  contextDisabled: boolean = true;
  contextVisible: boolean = false;
  tcORGDROPDOWNDISPLAY: string;

  orgMNames: OrganizationsByObjectAccessLookupModel[];
  selectedMOrgId: number;
  selectedMOrgDesc: string;

  orgTNames: OrganizationsByObjectAccessLookupModel[];
  selectedTOrgId: number;
  selectedTOrgDesc: string;

  orgONames: OrganizationsByObjectAccessLookupModel[];
  selectedOOrgId: number;
  selectedOOrgDesc: string;
  @Output() showDropDown = new EventEmitter<any>();
  private ngUnsubscribe = new Subject();

  // @Output() updateDataPerspectiveDesc = new EventEmitter<string>();
  private number: number;

  constructor(private lookupService: LookupsService, private router: Router,
              private contextService: ContextService, private confirmationService: ConfirmationService,
              private tenantConfigDataService: TenantConfigDataService) {
  }

  ngOnInit(): void {
    this.tcORGDROPDOWNDISPLAY = this.tenantConfigDataService.getStringValue('ORGDROPDOWNDISPLAY');
    // load available contexts
    this.contexts = [];
    for (const r of this.context.Contexts) {
      this.contexts.push({
        ID: r,
        Description: r.substring(0, 1) + r.substring(1).toLowerCase()
      });
    }
    if (this.context.selContext) {
      this.selectedContext = this.context.selContext;
    } else {
      this.selectedContext = this.context.Contexts[0];
      this.context.selContext = this.selectedContext;
    }
    // enable per page definitions
    switch (true) {
      case this.context.Contexts.length === 1:
        this.contextDisabled = true;
        this.contextVisible = false;
        break;
      case this.context.pageContexts.length === 0 && this.context.Contexts.length > 0:
        this.contextDisabled = false;
        this.contextVisible = true;
        break;
      case this.context.pageContexts.length === 1 && this.context.Contexts.length > 0:
        this.contextDisabled = true;
        this.contextVisible = true;
        this.selectedContext = this.context.pageContexts[0];
        this.context.selContext = this.selectedContext;
        break;
      case this.context.pageContexts.length > 1 && this.context.Contexts.length > 1:
        this.contextDisabled = false;
        this.contextVisible = true;
        break;
    }
    this.contextService.contextObject = this.context;
    // load data perspective dropdowns (should be one for each context possibility)
    // MEMBERSHIP
    this.selectedMOrgId = this.context.selectedMOrgId;
    this.selectedMOrgDesc = this.context.selectedMOrgDesc;
    this.lookupService.getOrganizationsDataPerspectiveMembershipLookup(true).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (res) => {
        this.orgMNames = res;
        this.orgMNames.forEach(x => {
          if (x.Abbreviation === null || x.Abbreviation === '') {
            x.Abbreviation = x.Description;
          }
        });
        this.context.selOrganizationId = this.selectedMOrgId;
        this.context.selOrganizationDesc = this.selectedMOrgDesc;
        this.contextService.contextObject = this.context;
      }
    });
    // TRAINING
    this.selectedTOrgId = this.context.selectedTOrgId;
    this.selectedTOrgDesc = this.context.selectedTOrgDesc;
    this.lookupService.getOrganizationsDataPerspectiveTrainingLookup(true).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (res) => {
        this.orgTNames = res;
        this.context.selOrganizationId = this.selectedTOrgId;
        this.context.selOrganizationDesc = this.selectedTOrgDesc;
        this.contextService.contextObject = this.context;
      }
    });
    // ORGANIZE
    this.selectedOOrgId = this.context.selectedOOrgId;
    this.selectedTOrgDesc = this.context.selectedOOrgDesc;
    this.lookupService.getOrganizationsDataPerspectiveOrganizeLookup(true).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (res) => {
        this.orgONames = res;
        this.context.selOrganizationId = this.selectedOOrgId;
        this.context.selOrganizationDesc = this.selectedOOrgDesc;
        this.contextService.contextObject = this.context;
      }
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  updateContext() {
    // update context
    this.context.selContext = this.selectedContext;
    this.contextService.contextObject = this.context;
    // re-apply organization perspective
    switch (this.selectedContext) {
      case 'MEMBERSHIP':
        this.context.selOrganizationId = this.selectedMOrgId;
        this.context.selOrganizationDesc = this.selectedMOrgDesc;
        break;
      case 'TRAINING':
        this.context.selOrganizationId = this.selectedTOrgId;
        this.context.selOrganizationDesc = this.selectedTOrgDesc;
        break;
      case 'ORGANIZE':
        this.context.selOrganizationId = this.selectedOOrgId;
        this.context.selOrganizationDesc = this.selectedOOrgDesc;
        break;
    }
    this.context.selOrganizationDesc = null;
    this.refreshPage(false);
  }

  updatePerspective() {
    let row: any[];
    // re-apply organzation perspective
    switch (this.selectedContext) {
      case 'MEMBERSHIP':
        this.context.selOrganizationId = this.selectedMOrgId;
        this.context.selectedMOrgId = this.selectedMOrgId;
        row = (this.orgMNames) ? this.orgMNames.filter(x => x.ID === this.context.selOrganizationId) : null;
        if (row && row.length === 1) {
          this.context.selOrganizationDesc = row[0].Description;
          this.context.selectedMOrgDesc = row[0].Description;
        } else {
          this.context.selOrganizationDesc = null;
          this.context.selectedMOrgDesc = null;
        }
        break;
      case 'TRAINING':
        this.context.selOrganizationId = this.selectedTOrgId;
        this.context.selectedTOrgId = this.selectedTOrgId;
        row = (this.orgTNames) ? this.orgTNames.filter(x => x.ID === this.context.selOrganizationId) : null;
        if (row && row.length === 1) {
          this.context.selOrganizationDesc = row[0].Description;
          this.context.selectedTOrgDesc = row[0].Description;
        } else {
          this.context.selOrganizationDesc = null;
          this.context.selectedTOrgDesc = null;
        }
        break;
      case 'ORGANIZE':
        this.context.selOrganizationId = this.selectedOOrgId;
        this.context.selectedOOrgId = this.selectedOOrgId;
        row = (this.orgONames) ? this.orgONames.filter(x => x.ID === this.context.selOrganizationId) : null;
        if (row && row.length === 1) {
          this.context.selOrganizationDesc = row[0].Description;
          this.context.selectedOOrgDesc = row[0].Description;
        } else {
          this.context.selOrganizationDesc = null;
          this.context.selectedOOrgDesc = null;
        }
        break;
    }
    this.contextService.contextObject = this.context;
    // only close window if org is selected
    if (this.context.selOrganizationId) {
      this.refreshPage(true);
    } else {
      this.refreshPage(false);
    }
  }

  // updateDataPerspectiveDisplay(): void {
  //   this.updateDataPerspectiveDesc.emit();
  // }

  refreshPage(closeDropDown: boolean) {
    // close the save prompt
    this.confirmationService.close();
    // let currentUrl = this.router.url;
    // if (currentUrl.includes('add') || currentUrl.includes('edit')) {
    //   currentUrl = currentUrl.replace(/edit.*$/ || /add.*$.*$/, 'list');
    //   this.router.navigate([currentUrl]);
    // } else {
    //   // TODO - add handling for query params
    //   this.router.navigateByUrl('/page-not-found', {skipLocationChange: true}).then(() => {
    //     this.router.navigate([currentUrl]);
    //   });
    // }
    location.reload();
    // this.updateDataPerspectiveDisplay();
    if (closeDropDown === true) {
      this.showDropDown.emit();
    }
  }

  close() {
    this.showDropDown.emit();
  }
}
