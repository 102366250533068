import {GenericSelectModel} from '@app/models/generic-select.model';
import {LookupModel} from '@app/models/lookups.model';
import {TenantConfigDataService} from './tenant-config-data.service';
import {takeUntil} from 'rxjs/operators';
import {LookupsDataService} from './lookups-data.service';
import {DistributionListDefinitions} from '@shared/definitions/distribution-list.definitions';
import {Subject} from 'rxjs';
import {CommunicationsService} from './communications.service';
import {AttributesService} from './attributes.service';
import {Injectable} from '@angular/core';
import {DistListLookupsModel} from '@app/models/dist-list-lookups.model';
import {ContextService} from './context.service';

@Injectable({
  providedIn: 'root',
})

export class DistListLookupService {
  genericInputObj = {} as GenericSelectModel;
  private ngUnsubscribe = new Subject();

  constructor(private tenantConfigDataService: TenantConfigDataService, private lookupsDataService: LookupsDataService,
              private distributionListDefinitions: DistributionListDefinitions, private communicationsService: CommunicationsService,
              private attributesService: AttributesService, private contextService: ContextService) {
  }

  public getDistLookups(distListParams: DistListLookupsModel) {
    return new Promise((resolve) => {
      // console.log(distListParams.columnName);
      const STRINGREPLACELOCAL = this.tenantConfigDataService.getStringValue('STRINGREPLACELOCAL');
      // const STRINGREPLACEOCCUPATION = this.tenantConfigDataService.getStringValue('STRINGREPLACEOCCUPATION');
      const STRINGREPLACECLASSIFICATION = this.tenantConfigDataService.getStringValue('STRINGREPLACECLASSIFICATION');
      const STRINGREPLACETYPE = this.tenantConfigDataService.getStringValue('STRINGREPLACETYPE');
      if (distListParams.columnName === 'STRINGREPLACECLASSIFICATION') {
        distListParams.columnName = STRINGREPLACECLASSIFICATION;
      }
      if (distListParams.columnName === 'STRINGREPLACETYPE') {
        distListParams.columnName = STRINGREPLACETYPE;
      }
      // console.log(distListParams.columnName);
      // console.log(distListParams.tableName);
      switch (distListParams.columnName) {
        case 'Membership Status' : {
          if (distListParams.tableName === 'COMMUNICATIONSTATUS') {
            this.genericInputObj.data = this.distributionListDefinitions.sendPublishStatusValues;
            resolve(this.genericInputObj.data);
          } else {
            // default to membership context for pickers
            this.lookupsDataService.getPersonProfileStatusesLookupData('membership', false).then((lookupData: LookupModel[]) => {
              this.genericInputObj.data = lookupData;
              resolve(this.genericInputObj.data);
            });
          }
          break;
        }
        case 'Status' : {
          if (distListParams.tableName === 'COMMUNICATIONSTATUS') {
            this.genericInputObj.data = this.distributionListDefinitions.sendPublishStatusValues;
            resolve(this.genericInputObj.data);
          } else {
            this.lookupsDataService.getPersonProfileStatusesLookupData('organize', false).then((lookupData) => {
              this.genericInputObj.data = lookupData;
              resolve(this.genericInputObj.data);
            });
          }
          break;
        }
        case STRINGREPLACELOCAL :
        case 'Local' : {
          this.lookupsDataService.getOrganizationsByObjectAccessLookupData(distListParams.interfaceObjectEnum, false, null, false).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'Membership ' + STRINGREPLACETYPE :
        case 'Membership Class' :
        case 'Membership Type' :
        case 'Class' : {
          // default to membership context for pickers
          this.lookupsDataService.getPersonProfileTypesLookupData('membership', false).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'Student Type' : {
          // default to membership context for pickers
          this.lookupsDataService.getPersonProfileTypesLookupData('training', false).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'Student Group' : {
          // default to membership context for pickers
          this.lookupsDataService.getStudentGroupsLookupData(0, false).then((lookupData) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'Position' :
        case 'Positions' : {
          // let orgId: number;
          let category: string;
          // if (distListParams.organizationID) {
          //   orgId = distListParams.organizationID;
          // } else if (this.contextService.contextObject.selectedMOrgId && this.contextService.contextObject.selContext === 'MEMBERSHIP') {
          //   orgId = this.contextService.contextObject.selectedMOrgId;
          // } else if (this.contextService.contextObject.RootMemberOrgID && this.contextService.contextObject.selContext === 'MEMBERSHIP') {
          //   orgId = this.contextService.contextObject.RootMemberOrgID;
          // } else if (this.contextService.contextObject.selectedTOrgId && this.contextService.contextObject.selContext === 'TRAINING') {
          //   orgId = this.contextService.contextObject.selectedTOrgId;
          // } else if (this.contextService.contextObject.RootTrainingOrgID && this.contextService.contextObject.selContext === 'TRAINING') {
          //   orgId = this.contextService.contextObject.RootTrainingOrgID;
          //   // } else if (this.contextService.contextObject.selectedOOrgId && this.contextService.contextObject.selContext === 'ORGANIZE') {
          //   //   orgId = this.contextService.contextObject.selectedOOrgId;
          //   // } else if (this.contextService.contextObject.RootOrganizeOrgID && this.contextService.contextObject.selContext === 'ORGANIZE') {
          //   //   orgId = this.contextService.contextObject.RootOrganizeOrgID;
          // }
          if (distListParams.category) {
            category = distListParams.category;
          } else {
            switch (this.contextService.contextObject.selContext) {
              case 'MEMBERSHIP':
                category = 'union';
                break;
              case 'TRAINING':
                category = 'education';
                break;
              // case 'ORGANIZE':
              //   category = '';
              //   break;
            }
          }
          // default to membership context for pickers
          this.lookupsDataService.getPositionsByOrganizationIdLookup(0, category, false).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'Position Type' : {
          // default to membership context for pickers
          this.lookupsDataService.getPositionTypesLookupData(false).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'Last Activity' : {
          // default to membership context for pickers
          this.lookupsDataService.getProfileActivitiesLookupData(false).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'Organization Type' : {
          this.lookupsDataService.getPositionOrganizationTypes(distListParams.category, false).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'Bargaining Unit' : {
          this.lookupsDataService.getBargainingUnitLookupData(false).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'Voter Status' : {
          this.lookupsDataService.getVoterStatus(false).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'Voter Registration Status' : {
          this.lookupsDataService.getVoterRegistrationStatus(false).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'Party Registration' : {
          this.lookupsDataService.getVoterParties(false).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'Course' : {
          this.lookupsDataService.getCoursesLookupData().then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'Training Status' : {
          if (distListParams.tableName === 'STUDENTCLASS') {
            this.lookupsDataService.getTrainingStatusesLookupData('class').then((lookupData: LookupModel[]) => {
              this.genericInputObj.data = lookupData;
              resolve(this.genericInputObj.data);
            });
          } else if (distListParams.tableName === 'STUDENTPROGRAM') {
            this.lookupsDataService.getTrainingStatusesLookupData('program').then((lookupData: LookupModel[]) => {
              this.genericInputObj.data = lookupData;
              resolve(this.genericInputObj.data);
            });
          }
          break;
        }
        case 'Result' : {
          this.lookupsDataService.getResultsLookupData().then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'Response' : {
          this.lookupsDataService.getSurveyQuestionLookupData(distListParams.surveyQuestionID).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'Communication' : {
          this.communicationsService.getCommunicationsPrimeNG('DATA', distListParams.contextEnumerator, distListParams.currentFilter, distListParams.organizationID)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
              next: (res) => {
                this.genericInputObj.data = [];
                res.Data.forEach(x => {
                  this.genericInputObj.data.push({Description: x.Title, ID: x.ID});
                });
                //this.genericInputObj.data = res;
                resolve(this.genericInputObj.data);
              }
            });
          break;
        }
        case 'Certification' : {
          this.lookupsDataService.getCertificationsLookupData().then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'Training Type' : {
          this.lookupsDataService.getPersonProfileTypesLookupData(distListParams.contextEnumerator, false).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'Student Status' : {
          this.lookupsDataService.getPersonProfileStatusesLookupData('training', false).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'Program Status' : {
          this.lookupsDataService.getTrainingStatusesLookupData('program', null, false).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'Primary Trade' :
        case 'Trade' : {
          this.lookupsDataService.getTradesLookupData(false).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;

            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'Primary Skill' : {
          this.lookupsDataService.getSkillsLookupData(false).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'Prefix' : {
          this.lookupsDataService.getHonorificsLookupData(false).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'Program Description' : {
          this.lookupsDataService.getAllProgramsPicklistLookupData(false).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'Program Type' : {
          this.lookupsDataService.getProgramTypesLookupData(false).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'Subject Area' : {
          this.lookupsDataService.getSubjectAreasLookupData(false).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'Training Center' : {
          this.lookupsDataService.getTrainingCentersLookupData(false).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        // https://utsmart-development.azurewebsites.net/api/1.0/common/trainingcenters
        case STRINGREPLACECLASSIFICATION :
        case 'SubClass' : {
          this.lookupsDataService.getPersonProfileClassificationsLookupData(true, false).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'Employer' :
        case 'Employer Name' : {
          if (distListParams.tableName === 'PROJECTEMPLOYER') {
            this.lookupsDataService.getProspectEmployersLookupData().then((lookupData: LookupModel[]) => {
              this.genericInputObj.data = lookupData;
              resolve(this.genericInputObj.data);
            });
          } else {
            this.lookupsDataService.getEmployersLookupByEnumData('roster', false).then((lookupData: LookupModel[]) => {
              this.genericInputObj.data = lookupData;
              resolve(this.genericInputObj.data);
            });
          }

          break;
        }
        case 'Funding' : {
          this.lookupsDataService.getOrganizeProjectFundingLookupData(false).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'Phase' : {
          this.lookupsDataService.getOrganizeProjectPhasesLookupData(false).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'Type' : {
          if (distListParams.tableName === 'PROJECTEMPLOYER') {
            this.lookupsDataService.getOrganizeProjectOrganizationType(false).then((lookupData: LookupModel[]) => {
              this.genericInputObj.data = lookupData;
              resolve(this.genericInputObj.data);
            });
          } else {
            this.lookupsDataService.getOrganizeProjectTypesListLookupData(false).then((lookupData: LookupModel[]) => {
              this.genericInputObj.data = lookupData;
              resolve(this.genericInputObj.data);
            });
          }

          break;
        }
        case 'Value' : {
          this.lookupsDataService.getOrganizeProjectEstimatedLookupData(false).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'Industry' : {
          this.lookupsDataService.getOrganizeProjectIndustriesListLookupData(false).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        // case 'Worksite' : {
        //   this.lookupsDataService.getWorksitesLookupData(false).then((lookupData: LookupModel[]) => {
        //     this.genericInputObj.data = lookupData;
        //     resolve(this.genericInputObj.data);
        //   });
        //   break;
        // }
        case 'Gender' : {
          this.genericInputObj.data = [{
            ID: 0,
            Description: 'F'
          }, {
            ID: 0,
            Description: 'M'
          }, {
            ID: 0,
            Description: 'U'
          }];
          resolve(this.genericInputObj.data);
          break;
        }
        case 'Ethnicity' : {
          this.lookupsDataService.getEthnicityLookupData(false).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'Race/Ethnicity' : {
          this.lookupsDataService.getRaceLookupData(false).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'Language' :
        case 'Preferred Language' : {
          this.lookupsDataService.getLanguagesLookupData(false).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'Education' : {
          this.lookupsDataService.getEducationLookupData(false).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'Military Branch' : {
          this.lookupsDataService.getMilitaryLookupData(false).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'Military Status' : {
          this.lookupsDataService.getMilitaryStatusLookupData().then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'Shift' : {
          this.lookupsDataService.getShiftsLookupData(distListParams.organizationID, false).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'Billing Process' : {
          if (distListParams.organizationID) {
            this.lookupsDataService.getMoneyManagerStatusesByOrganizationIdLookupData(distListParams.organizationID).then((lookupData: LookupModel[]) => {
              this.genericInputObj.data = lookupData;
              resolve(this.genericInputObj.data);
            });
          } else {
            this.lookupsDataService.getMoneyManagerStatusesLookupData().then((lookupData: LookupModel[]) => {
              this.genericInputObj.data = lookupData;
              resolve(this.genericInputObj.data);
            });
          }
          break;
        }
        case 'Dues Payment Election' : {
          this.lookupsDataService.getMoneyManagerPaymentTypesLookupData().then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'Dues Group' : {
          this.lookupsDataService.getDuesGroupsLookupData(false).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'Organizers' : {
          this.lookupsDataService.getOrganizeProjectOrganizers(distListParams.organizationID, false).then((lookupData: LookupModel[]) => {
            this.genericInputObj.data = lookupData;
            resolve(this.genericInputObj.data);
          });
          break;
        }
        case 'Undeliverable' : {
          this.genericInputObj.data = [{
            ID: 0,
            Description: 'Deliverable'
          }, {
            ID: 0,
            Description: 'Not Deliverable'
          }];
          resolve(this.genericInputObj.data);
          break;
        }
        default : {
          console.log('no match found for ' + distListParams.columnName);
        }
      }
      if (distListParams.tableName === 'ATTRIBUTE' || distListParams.tableName === 'STUDENTATTRIBUTE') {
        this.attributesService.getAttribute(distListParams.setAttributeID)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: (res) => {
              this.genericInputObj.data = res.Options;
              resolve(this.genericInputObj.data);
            }
          });
      }

      if (distListParams.tableName === 'EMPATTRIBUTE') {
        this.attributesService.getAttribute(distListParams.setAttributeID)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(res => {
            this.genericInputObj.data = res.Options;
            resolve(this.genericInputObj.data);
          });
      }


    });
  }
}
