
<div class="page-container">
  <div class="public-card">
    <p-card class="card">
      @if (logo) {
        <div class="align-center">
          <img class="public-logo img-width100" ngSrc="assets/images/{{logo}}" alt="" width="286" height="76" [priority]="true" />
        </div>
      }
      @if (!noEvents) {
        <div class="margin-top-10">
          <h2>Connect With Your Union!</h2>
          <p>We offer a wide variety of training and events to ensure you get the most out of your membership.</p>
        </div>
      }
      @if (!isEdit && !noEvents) {
        <div class="margin-top-10">
          <h3>Upcoming Events</h3>
          @for (e of eventInstance; track e) {
            <div class="margin-top-15">
              @for (ei of e.EventInstancees; track ei) {
                <p-card class="card parent-p-card">
                  <div class="p-grid p-fluid">
                    <div class="col-md-7 col-sm-12 col-xs-12">
                      <h3>{{e.EventName}}</h3>
                      <div [innerHTML]="e.EventDescription"></div>
                      <button pButton label="Sign Up" class="p-mt-4" (click)="eventSelected(e, ei)"></button>
                    </div>
                    <div class="col-md-1 col-sm-0 col-xs-0">
                      <p-divider layout="vertical"></p-divider>
                    </div>
                    <div class="col-md-4 col-sm-12 col-xs-12">
                      @if (ei.StartDate_D) {
                        <div>
                          <br>
                            <div>Date</div>
                            <label>{{ei.StartDate_D | date: 'longDate'}}@if (ei.EndDate_D && ei.StartDate_D !== ei.EndDate_D) {
                              <span> - {{ei.EndDate_D | date: 'longDate'}}</span>
                            }</label>
                          </div>
                        }
                        @if (ei.StartTime_T) {
                          <div>
                            <br>
                              <div>Time</div>
                              <label>{{ei.StartTime_T | date: 'shortTime'}}</label>
                            </div>
                          }
                          @if (ei.TimezoneDescription) {
                            <div>
                              <br>
                                <label>{{ei.TimezoneDescription}}</label>
                              </div>
                            }
                          </div>
                        </div>
                      </p-card>
                    }
                  </div>
                }
              </div>
            }
            @if (isEdit && !noEvents) {
              <div>
                <h3>Event Registration</h3>
                <div class="parent-p-card margin-top-15">
                  <p-card class="card">
                    <form [formGroup]="mainForm">
                      <div class="p-grid p-fluid">
                        <div class="col-md-7 col-sm-12 col-xs-12">
                          <h3>{{selectedEvent.EventName}}</h3>
                          <div [innerHTML]="selectedEvent.EventDescription"></div>
                          <label><b>Start:</b> {{selectedEventInstance.StartDate_D | date: 'longDate'}}</label>
                          @if (selectedEventInstance.EndDate_D && selectedEventInstance.StartDate_D !== selectedEventInstance.EndDate_D) {
                            <label><b>End:</b> {{selectedEventInstance.EndDate_D | date: 'longDate'}}</label>
                          }
                          <br>
                            @if (locationDataLoaded && locationData?.length <= 0) {
                              <h3>This event is currently full.</h3>
                            }
                            @if (locationDataLoaded && locationData?.length > 0) {
                              <app-select class="full-width" [inputObj]="inputObjLocation" (emitData)="getLocationData($event)"></app-select>
                            }
                            @if (locationDisplay && locationDataLoaded && locationData?.length > 0) {
                              <div>
                                <label [innerHTML]="locationDisplay"></label>
                              </div>
                            }
                            <!--                    <div *ngIf="apiLoaded">-->
                            <!--                      <google-map-->
                            <!--                        height="350px"-->
                            <!--                        width="100%"-->
                            <!--                        [zoom]="zoom"-->
                            <!--                        [center]="center">-->
                            <!--                        <map-marker #marker="mapMarker"-->
                            <!--                                    *ngFor="let markerPosition of markerPositions"-->
                            <!--                                    [position]="markerPosition"-->
                          <!--                                    (mapClick)="openInfoWindow(marker)"></map-marker>-->
                          <!--                        <map-info-window> {{address}} <br />-->
                          <!--                          {{city}}, {{state}} {{zip}}-->
                        <!--                        </map-info-window>-->
                      <!--                      </google-map>-->
                    <!--                    </div>-->
                  </div>
                  <div class="col-md-1 col-sm-0 col-xs-0">
                    <p-divider layout="vertical"></p-divider>
                  </div>
                  @if (locationDataLoaded && locationData?.length > 0) {
                    <div class="col-md-4 col-sm-12 col-xs-12">
                      @if (selectedEventInstance.StartDate_D) {
                        <div>
                          <br>
                            <div>Date</div>
                            <label>{{selectedEventInstance.StartDate_D | date: 'longDate'}}@if (selectedEventInstance.EndDate_D && selectedEventInstance.StartDate_D !== selectedEventInstance.EndDate_D) {
                              <span> - {{selectedEventInstance.EndDate_D | date: 'longDate'}}</span>
                            }</label>
                          </div>
                        }
                        @if (selectedEventInstance.StartTime_T) {
                          <div>
                            <br>
                              <div>Time</div>
                              <label>{{selectedEventInstance.StartTime_T | date: 'shortTime'}}</label>
                            </div>
                          }
                          @if (selectedEventInstance.TimezoneDescription) {
                            <div>
                              <label>{{selectedEventInstance.TimezoneDescription}}</label>
                            </div>
                          }
                          @if (locationDisplayWithName) {
                            <div>
                              <br>
                                <div>Location</div>
                                <label [innerHTML]="locationDisplayWithName"></label>
                              </div>
                            }
                            <br>
                              <div class="input-container">
                                <label>First Name <sup class="req">*</sup></label>
                                <input class="full-width" type="text" pInputText  formControlName="firstName">
                                @if ((mainForm.controls.firstName.dirty) &&
                                  mainForm.controls.firstName.errors?.required) {
                                  <span class="error">'First Name' must be provided</span>
                                }
                              </div>
                              <div class="input-container">
                                <label>Last Name <sup class="req">*</sup></label>
                                <input class="full-width" type="text" pInputText  formControlName="lastName">
                                @if ((mainForm.controls.lastName.dirty) &&
                                  mainForm.controls.lastName.errors?.required) {
                                  <span class="error">'Last Name' must be provided</span>
                                }
                              </div>
                              <div class="input-container mask-container">
                                <label>Mobile Phone #</label>
                                <p-inputMask type="text" pInputText formControlName="phone" [className]="'form-input no-border'" mask="(999) 999-9999" unmask="true"></p-inputMask>
                              </div>
                              <div class="input-container">
                                <label>E-mail <sup class="req">*</sup></label>
                                <input class="full-width" type="text" pInputText  formControlName="email">
                                @if ((mainForm.controls.email.dirty) &&
                                  mainForm.controls.email.errors?.required) {
                                  <span class="error">'E-mail' must be provided</span>
                                }
                                @if ((mainForm.controls.email.dirty) &&
                                  mainForm.controls.email?.errors) {
                                  <span class="error">'E-mail' must be valid</span>
                                }
                              </div>
                              <div class="input-container">
                                <label>Postal Code <sup class="req">*</sup></label>
                                <input class="full-width" type="text" pInputText  formControlName="postalCode">
                                @if ((mainForm.controls.postalCode.dirty) &&
                                  mainForm.controls.postalCode.errors?.required) {
                                  <span class="error">'Postal Code' must be provided</span>
                                }
                              </div>
                              <div class="input-container">
                                <p>By registering for this event, you are opting-in to occasional text message updates about this event and other events we offer.</p>
                                @if (!isRegistering) {
                                  <button pButton label="Register" class="p-button-info" pTooltip="Register" tooltipPosition="bottom"
                                  (click)="register()" [disabled]="mainForm.invalid || !mainForm.dirty"></button>
                                }
                                @if (isRegistering) {
                                  <button pButton label="Register" class="p-button-info" [disabled]="true">
                                    <i class="pi pi-spin pi-spinner save-spinner"></i>
                                  </button>
                                }
                              </div>
                            </div>
                          }
                        </div>
                      </form>
                    </p-card>
                  </div>
                  <div class="p-mt-4">
                    <button pButton label="Back to Events" (click)="isEdit=false;" class="p-button-info" [className]="'back-button'"
                    icon="pi pi-angle-left" pTooltip="Back" tooltipPosition="bottom"></button>
                  </div>
                </div>
              }
              @if (noEvents) {
                <div class="margin-top-10">
                  <h2>We're busy planning our next events, however...</h2>
                  <p>No events are currently scheduled. Please come back to check again at a later time, thank you!</p>
                </div>
              }
            </p-card>
          </div>
          <p-toast [baseZIndex]="16000"></p-toast>
        </div>
