import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {DatePipe} from '@angular/common';
import moment from 'moment/moment';

export function NotZeroValidation(): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {
    const selectValid = control.value !== 0 && control.value !== null;
    return !selectValid ? {notZero: true} : null;
  };
}

export function DOBValid(): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {
    // const selectValid = control.value !== 0 && control.value !== null;
    let selectValid;
    if(control.value) {
      const stringDate = control.value.toString();
      if(stringDate && stringDate !== '') {
        const dateString = stringDate.replaceAll('-', '');
        const newDate = moment();
        newDate.set('year', dateString.slice(0, 4));
        newDate.set('month', dateString.slice(5, 2));
        newDate.set('date', dateString.slice(7, 2));
        newDate.startOf('day');
        const minDate = moment().subtract(130, 'years').toDate();
        const maxDate = moment().toDate();
        selectValid = moment(newDate).toDate() < minDate || moment(newDate).toDate() > maxDate;
        return selectValid ? {notValid: true} : null;
      } else {
        return selectValid ? {notValid: true} : null;
      }
    }

  };
}

export function NotNegativeValidation(): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {
    const selectValid = control.value >= 0 && control.value !== null;
    return !selectValid ? {notNegative: true} : null;
  };
}

export function transformDateTimeToUTC(dateTime: string): string {
  const pipe = new DatePipe('en-US');
  return (dateTime && dateTime !== '' && !dateTime.toString().includes('function')) ? pipe.transform(dateTime, 'yyyy-MM-ddThh:mm:ss') : null;
}

export function transformDateTimeToDateOnly(dateTime: string): string {
  const pipe = new DatePipe('en-US');
  return (dateTime && dateTime !== '' && !dateTime.toString().includes('function')) ? pipe.transform(dateTime, 'yyyy-MM-dd') : null;
}

export function transformDateTimeToTimeOnly(dateTime: string): string {
  const pipe = new DatePipe('en-US');
  return (dateTime && dateTime !== '' && !dateTime.toString().includes('function')) ? '1900-01-01 ' + pipe.transform(dateTime, 'h:mm a') : null;
}

export function transformDateFormat(dateTime: string): string {
  return dateTime.replace(/^(\d{2})(\d{2})/, '$1/$2/');
}

// export function transformDateTimeToLocalTimezone(dt: string, argDate: boolean, argTime: boolean): string {
//   let dateArr = [];
//   let rtn = null;
//   let newDate;
//   let newTime;
//   let hours;
//   let parsedDate;
//   if (dt !== null && dt !== undefined && dt !== '') {
//     if (dt && Object.prototype.toString.call(dt) === '[object Date]') {//} && !isNaN(dt)) {
//       newDate = new Date(dt);
//     } else {
//       if (dt.toString().substring(dt.toString().length - 1) === 'Z') {
//         dt = dt.toString().replace('Z', '');
//       }
//       dateArr = dt.toString().split(/[-T:]/);
//       newDate = new Date(dateArr[0], Number(dateArr[1]) - 1, dateArr[2], dateArr[3], dateArr[4], dateArr[5]);
//     }
//     if (newDate.getTime() !== null && newDate.getTime() !== undefined) {
//       if (newDate.getHours() + newDate.getMinutes() + newDate.getSeconds() > 0) {
//         hours = newDate.getTimezoneOffset() / 60;
//         newDate.setHours(newDate.getHours() - hours);
//         parsedDate = new Date(Date.parse(newDate));
//       } else {
//         parsedDate = new Date(Date.parse(newDate));
//       }
//     } else {
//       parsedDate = new Date(Date.parse(newDate));
//     }
//     if (argDate === true) {
//       rtn = ('0' + (parsedDate.getMonth() + 1)).slice(-2) + '/' + ('0' + parsedDate.getDate()).slice(-2) + '/' + parsedDate.getFullYear() + '  ';
//     }
//     if (argTime === true) {
//       newTime = new Date(newDate);
//       if (rtn !== null && rtn !== undefined) {
//         if (newTime.getHours() > 12) {
//           rtn += newTime.getHours() - 12;
//         } else {
//           rtn += newTime.getHours();
//         }
//         if (newTime.getMinutes() < 10) {
//           rtn += ':0' + newTime.getMinutes();
//         } else {
//           rtn += ':' + newTime.getMinutes();
//         }
//         if (newTime.getHours() > 11) {
//           rtn += ' PM';
//         } else {
//           rtn += ' AM';
//         }
//       } else {
//         if (newTime.getHours() > 12) {
//           rtn += newTime.getHours() - 12;
//         } else {
//           rtn += newTime.getHours();
//         }
//         if (newTime.getMinutes() < 10) {
//           rtn += ':0' + newTime.getMinutes();
//         } else {
//           rtn += ':' + newTime.getMinutes();
//         }
//         if (newTime.getHours() > 11) {
//           rtn += ' PM';
//         } else {
//           rtn += ' AM';
//         }
//       }
//     }
//   } else {
//     rtn = '';
//   }
//   return rtn;
// }

export function dateDiffInYearsMonths(dt1: Date, dt2: Date): string {
  const a: Date = new Date(dt1);
  const b: Date = new Date(dt2);
  let years: number;
  let months: number;
  let rtn: string = '';
  if (a.getMonth() > b.getMonth()) {
    years = Math.floor(b.getFullYear() - a.getFullYear() - 1);
  } else {
    years = Math.floor(b.getFullYear() - a.getFullYear());
  }
  switch (true) {
    case years === 1:
      rtn = years.toString() + ' year';
      break;
    case years > 1:
      rtn = years.toString() + ' years';
      break;
  }
  if (b.getMonth() < a.getMonth()) {
    months = 11 - a.getMonth() + b.getMonth();
  } else {
    months = b.getMonth() - a.getMonth();
  }
  switch (true) {
    case months === 1:
      if (rtn !== '') {
        rtn += ', ' + months + ' month';
      } else {
        rtn = months + ' month';
      }
      break;
    case months > 1:
      if (rtn !== '') {
        rtn += ', ' + months + ' months';
      } else {
        rtn = months + ' months';
      }
      break;
  }
  return rtn;
}
