import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {LookupModel} from '@app/models/lookups.model';
import {GenericSelectModel} from '@app/models/generic-select.model';

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html'
})
export class MultiSelectComponent implements OnInit, OnChanges, AfterViewInit {
  showFilter: boolean = false;
  selectForm: FormGroup;
  selectData: LookupModel[];
  selectedData: LookupModel[] = [];
  virtScroll: boolean;
  selectAll: boolean;
  @Input() inputObj: GenericSelectModel;
  @Input() newValue: any;
  @Output() emitData = new EventEmitter<any>();
  @Output() emitBlur = new EventEmitter<any>();

  constructor(private formBuilder: FormBuilder, private changeDetector: ChangeDetectorRef) {
    this.selectForm = this.formBuilder.group({
      fcnSelect: [null]
    });
  }

  ngOnInit(): void {
    if (this.inputObj) {
      if (typeof this.inputObj.customSelect == 'undefined') {
        this.inputObj.customSelect = null;
      }
    }
  }

  // reload if params change
  ngOnChanges(changes: SimpleChanges): void {
    if (this.inputObj) {
      if (typeof this.inputObj.customSelect == 'undefined') {
        this.inputObj.customSelect = null;
      }
      this.loadSelect();
      if (this.inputObj.canWrite) {
        if (this.inputObj.disabled === true) {
          this.selectForm.get('fcnSelect').disable();
        } else {
          this.selectForm.get('fcnSelect').enable();
        }
      } else {
        this.selectForm.get('fcnSelect').disable();
      }
    } else {
      this.selectForm.get('fcnSelect').disable();
    }
    if (changes.newValue) {
      if (this.inputObj && this.newValue && this.inputObj.initSelected !== this.newValue) {
        this.selectForm.get('fcnSelect').setValue(this.newValue);
        this.emitDataToParent(true);
      }
    }
  }

  ngAfterViewInit(): void {
    this.changeDetector.detectChanges();
  }

  // required to listen to form controls
  get registerFormControl(): any {
    return this.selectForm.controls;
  }

  loadSelect(): void {
    if (this.inputObj.data) {
      this.selectData = this.inputObj.data;
      if (this.inputObj.data.length > 100) {
        this.virtScroll = true;
      }
    }
    if (this.inputObj.requiredField) {
      this.selectForm.get('fcnSelect').setValidators([Validators.required]);
    }
    if (this.inputObj.selectFirstValue) {
      let id: number;
      let desc: string;
      if (this.selectData) {
        for (let i = 0, ilen = this.selectData.length; i < ilen; i++) {
          if (this.inputObj.optionValue === 'Description') {
            desc = this.selectData[i].Description;
            if (desc !== '--') {
              break;
            }
          } else {
            id = this.selectData[i].ID;
            if (id) {
              break;
            }
          }
        }
        switch (this.inputObj.optionValue) {
          case 'ID':
            this.selectForm.get('fcnSelect').setValue(id);
            break;
          case 'Description':
            this.selectForm.get('fcnSelect').setValue(desc);
            break;
        }
      }
    } else {
      if (this.inputObj.initSelected) {
        this.selectForm.get('fcnSelect').setValue(this.inputObj.initSelected);
      } else {
        this.selectForm.get('fcnSelect').setValue(null);
      }
    }
    if (this.selectData) {
      this.showFilter = Object.keys(this.selectData).length > 10;
      if (this.inputObj.emitChangeOnLoad === undefined) {
        this.emitDataToParent(false);
      } else {
        this.emitDataToParent(this.inputObj.emitChangeOnLoad);
      }
    }
    if (this.inputObj.canWrite === undefined) {
      this.inputObj.canWrite = true;
    }
  }

  onSelectAllChange(event: any) {
    if (event.checked) {
      switch (this.inputObj.optionValue) {
        case 'ID': {
          this.selectData = this.selectData.filter(x => x.ID !== null);
          const tempArrayNumber: number[] = [];
          this.selectData.forEach(data => {
            tempArrayNumber.push(data.ID);
          });
          this.selectForm.get('fcnSelect').setValue(tempArrayNumber);
          break;
        }
        case 'Description' : {
          this.selectData = this.selectData.filter(x => x.Description !== '--');
          const tempArrayString: string[] = [];
          this.selectData.forEach(data => {
            tempArrayString.push(data.Description);
          });
          this.selectForm.get('fcnSelect').setValue(tempArrayString);
          break;
        }
      }
    } else {

      this.selectForm.get('fcnSelect').setValue([]);
    }
    this.selectAll = event.checked;
    // console.log(this.selectForm.get('fcnSelect').value);
  }

  emitDataToParent(emit: boolean): void {
    if (emit && this.selectForm.get('fcnSelect').value) {
      if (this.selectForm.get('fcnSelect').value) {
        this.emitData.emit(this.selectForm.get('fcnSelect').value);
      } else {
        this.emitData.emit([]);
      }
    }
  }

  sendOnBlur(event: any) {
    this.emitBlur.emit();
  }
}
