import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {ContextService} from './context.service';

import {SurveyQuestionModel, SurveyQuestionResponsesGridModel, UpdateSurveyQuestionPickListModel, UpdateSurveyQuestionRangeModel, UpdateSurveyQuestionYesNoModel} from '@app/models/survey-questions.model';

@Injectable({
  providedIn: 'root',
})
export class SurveyQuestionsService {
  constructor(private http: HttpClient, private contextService: ContextService) {
  }

  // getSurveyQuestionsKGrid(surveyId: number): Observable<SurveyQuestionsGridModel> {
  //   return this.http.get<SurveyQuestionsGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/survey/${surveyId}/questiongrid`);
  // }

  getSurveyQuestion(surveyQuestionId: number): Observable<SurveyQuestionModel> {
    return this.http.get<SurveyQuestionModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/survey/question/${surveyQuestionId}`);
  }

  updateSurveyQuestion(surveyQuestionId: number, modelData: SurveyQuestionModel): Observable<SurveyQuestionModel> {
    return this.http.put<SurveyQuestionModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/survey/question/${surveyQuestionId}`, modelData);
  }

  updateSurveyQuestionYesNo(surveyQuestionId: number, modelData: UpdateSurveyQuestionYesNoModel): Observable<SurveyQuestionModel> {
    return this.http.put<SurveyQuestionModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/survey/question/${surveyQuestionId}`, modelData);
  }

  updateSurveyQuestionRange(surveyQuestionId: number, modelData: UpdateSurveyQuestionRangeModel): Observable<SurveyQuestionModel> {
    return this.http.put<SurveyQuestionModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/survey/question/${surveyQuestionId}`, modelData);
  }

  updateSurveyQuestionPickList(surveyQuestionId: number, modelData: UpdateSurveyQuestionPickListModel): Observable<SurveyQuestionModel> {
    return this.http.put<SurveyQuestionModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/survey/question/${surveyQuestionId}`, modelData);
  }

  createSurveyQuestion(surveyId: number): Observable<SurveyQuestionModel> {
    return this.http.post<SurveyQuestionModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/survey/${surveyId}/createquestion`, null);
  }

  deleteSurveyQuestion(surveyQuestionId: number): Observable<any> {
    return this.http.delete<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/survey/question/remove/${surveyQuestionId}`);
  }

  reorderSurveyQuestions(surveyQuestionId: number, surveyQuestionId2: number): Observable<any> {
    return this.http.post<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/survey/question/reorder?id1=${surveyQuestionId}&id2=${surveyQuestionId2}`, null);
  }

  getSurveyQuestionResponsesPrimeNG(dataMode: string, surveyQuestionId: number, communicationId: number, filterData): Observable<SurveyQuestionResponsesGridModel> {
    return this.http.post<SurveyQuestionResponsesGridModel>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/superuser/survey/question/${surveyQuestionId}/responses/${communicationId}?mode=${dataMode}`, filterData);
  }
}
