import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ContextService} from './context.service';

@Injectable({
  providedIn: 'root',
})
export class SystemService {
  constructor(private http: HttpClient, private contextService: ContextService) {}

  clearUserCache(): Observable<any> {
    return this.http.put<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/system/clearusercache`, null);
  }

  clearTenantCache(): Observable<any> {
    return this.http.put<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/system/cleartenantcache`, null);
  }

  clearSystemCache(): Observable<any> {
    return this.http.put<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/system/clearsystemcache`, null);
  }

  serverStatusCheck(): Observable<any> {
    return this.http.get<any>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/system/checkserver`);
  }

  checkOrigin(): Observable<string> {
    return this.http.get<string>(`${this.contextService.contextObject.apiBaseUrlV1}/api/1.0/system/checkorigin`);
  }
}
