import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {LearnMoreService} from '@services/learn-more.service';
import {InterfaceObjectHelp} from '@app/models/interface-object-help.model';
import {ContextService} from '@services/context.service';
import {PrimeTableFilterModel} from '@app/models/table-filter.model';

@Component({
  selector: 'app-learn-more',
  templateUrl: './learn-more.component.html',
  styleUrls: ['./learn-more.component.scss'],
})
export class LearnMoreComponent implements OnInit, OnChanges {
  learnMore: InterfaceObjectHelp;
  showHelp: boolean;
  currentFilter = new PrimeTableFilterModel();
  @Input() interfaceObjectTypeEnum: string;
  @Input() interfaceObjectDesc: string;
  @Input() parentObjectEnum: string;
  @Input() report: boolean = false;

  constructor(private learnMoreService: LearnMoreService, private contextService: ContextService) {
  }

  ngOnInit(): void {
    this.showHelp = this.contextService.contextObject.UserPreferences?.Global_ShowHelpDefault === 'Show' && !this.contextService.contextObject.isMobile;
  }

  ngOnChanges(): void {
    if (this.interfaceObjectTypeEnum === 'APP') {
      this.currentFilter.filters = {
        PageObjectEnumerator: [
          {
            value: this.interfaceObjectDesc,
            matchMode: 'equals',
            operator: 'and'
          }
        ],
        ElementObjectTypeEnumerator: [
          {
            value: this.interfaceObjectTypeEnum,
            matchMode: 'equals',
            operator: 'or'
          },
          {
            value: null,
            matchMode: 'isnullorempty',
            operator: 'or'
          }
        ]
      };
    } else if (this.interfaceObjectTypeEnum === 'APPSECTION') {
      this.currentFilter.filters = {
        ElementObjectDescription: [
          {
            value: this.interfaceObjectDesc,
            matchMode: 'equals',
            operator: 'and'
          }
        ],
        ElementObjectTypeEnumerator: [
          {
            value: this.interfaceObjectTypeEnum,
            matchMode: 'equals',
            operator: 'and'
          }
        ],
        PageObjectEnumerator: [
          {
            value: this.parentObjectEnum,
            matchMode: 'equals',
            operator: 'and'
          }
        ]
      };
    } else {
      this.currentFilter.filters = {};
    }
    this.learnMoreService.getLearnMoreContentDataCached(this.interfaceObjectTypeEnum, this.interfaceObjectDesc, null, this.parentObjectEnum).then((lm) => {
      this.learnMore = lm;
    });
  }
}
